/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 24/4/2021
 * @Example
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'diginet-core-ui/components';
import W39F6000Cmt from './W39F6000Cmt';

W39F6000GroupCmt.propTypes = {
    classes: PropTypes.object,
    groupData: PropTypes.array,
    date: PropTypes.string,
    loadData: PropTypes.func,
    handleLoading: PropTypes.func
};

W39F6000GroupCmt.defaultProps = {
    classes: {},
    groupData: [],
    date: '',
    loadData: null,
    handleLoading: null
};

function W39F6000GroupCmt(props) {
    const { groupData, date, classes, loadData, handleLoading } = props;

    return (
        <div className={`${classes.flex} ${classes.justifyCenter} ${classes.divGroupCmtCont}`}>
            <Typography type={'p3'} className={classes.groupDate}>{date ?? ''}</Typography>
            <div className={`${classes.flex} ${classes.columnDirection} ${classes.gap10} ${classes.fullWidth} ${classes.alignItemsStart}`}>
                {groupData.map((i, idx) => <W39F6000Cmt key={idx} comment={i} classes={classes} loadData={loadData} handleLoading={handleLoading} />)}
            </div>
        </div>
    );
}

export default W39F6000GroupCmt;