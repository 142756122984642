/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/23/2019
 * @Example
 */

import React, { useEffect, useRef, useState, memo, useMemo } from 'react';
import ActionToolbar from '../../../common/toolbar/action-toolbar';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from '../../../../redux/main/main_actions';
import * as w39F3040Actions from '../../../../redux/W3X/W39F3040/W39F3040_actions';
import * as generalActions from '../../../../redux/general/general_actions';
import { FormGroup, Row, Col } from 'react-bootstrap';
import html2canvas from 'html2canvas';
import Config from '../../../../config';
import {Line} from 'react-chartjs-2';
import {
    Typography,
    Avatar,
    Dropdown,
    Button,
    TreeView,
    ButtonIcon,
    Modal,
    ModalHeader,
    ModalBody,
} from 'diginet-core-ui/components';
import GridContainer from '../../../grid-container/grid-container';
import { Column } from 'devextreme-react/data-grid';
import Icons from 'diginet-core-ui/icons';
import Filter from '../../../filter/filter';
import _ from 'lodash';
import moment from 'moment';
import { useTheme, makeStyles } from 'diginet-core-ui/theme';
import W39F3002 from '../W39F3002/W39F3002';
import W39F1011 from '../W39F1011/W39F1011';
import W39F3000 from '../W39F3000/W39F3000';
import W39F3036 from '../W39F3036/W39F3036';

const { colors, spacing } = useTheme();
const {
    white,
    fill: { extrabutton },
    system: { dark },
    text: { sub },
    disabled: disabledCL,
    line: { category, system },
    warning
} = colors;

const useStyle = makeStyles(themes => ({
    W39F3040: {
        minWidth: 960,
        '& .grid-container': {
            minHeight: 'calc(100vh - 195px)',
            height: 'calc(100vh - 195px)',
        },
        '& .paging': {
            minWidth: 'auto',
        },
    },
    TreeViewItem: {
        '& .View': {
            display: 'none',
        },
        '&:hover': {
            '& .View': {
                display: 'flex',
            },
        },
    },
    dot: {
        display: 'block',
        width: spacing(2),
        height: spacing(2),
        backgroundColor: dark,
        borderRadius: 100,
        marginRight: spacing(2),
    },
    treeView: {
        maxHeight: 'calc(100% - 160px)',
        overflow: 'auto',
    },
    line:{
        borderBottom: `1px solid ${category}`,
        marginTop: 0
    }
}));

const initData = {
    rows: [],
    total: 0,
};

const FormID = 'W39F3040';

const W39F3040 = props => {
    const {getCboPosition} = props;

    const [dataGrid, setDataGrid] = useState(initData);
    const [dataCboEmployees, setDataCboEmployees] = useState(initData);
    const [loadEmployees, setLoadEmployees] = useState(false);
    const [userSelected, setUserSelected] = useState(null);
    const [dataOrgChart, setDataOrgChart] = useState(null);
    const [cboEmployeeLoading, setCboEmployeeLoading] = useState(null);
    const [groupDuty, setGroupDuty] = useState(null);
    const [treeList, setTreeList] = useState(null);
    const [popupDetail, setPopupDetail] = useState(null);
    const [permission, setPermission] = useState(null);
    const [dataChart, setDataChart] = useState(null);
    const [dataDDFilter, setDataDDFilter] = useState({});
    const [stateNumYear, setStateNumYear] = useState(3);
    const [isExportPDF, setIsExportPDF] = useState(false);
    const [sizeChart, setSizeChart] = useState(null);
    const viewExportPDF = useRef(null);
    const classes = useStyle();
    let isESS = useRef(!_.toNumber(Config.getLocalStorage('MENUTYPEDHR') || 0));
    isESS = +isESS?.current;

    let getEmployeeID = '';
    if (isESS) getEmployeeID = Config.getHREmployeeID();
    const [filterSearch, setFilterSearch] = useState({
        NumYear: 3,
        EmployeeID: getEmployeeID,
        OrgChartID: '',
        DutyGroupID: '',
        DutyID: '',
    });

    const filterSearchRef = useRef({
        skip: 0,
        limit: 20,
    });

    const filterCboEmployees = useRef({
        Type: 'EmployeeID',
        FormID,
        skip: 0,
        limit: 50,
    });

    useEffect(() => {
        loadPermission();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (permission > 0) loadForm();
        // eslint-disable-next-line
    }, [permission]);

    useEffect(()=>{
        if(userSelected?.EmployeeID) loadTreeList();
        // eslint-disable-next-line
    },[userSelected?.EmployeeID]);

    useEffect( ()=>{
        if(!!isExportPDF){
            const input = document.getElementById('view-export-pdf');
            html2canvas(input, {useCORS: true, allowTaint: true, scale: 2})
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    if (canvas && imgData) {
                        try {
                            const myWindow = window.open('', Config.lang('Bang_muc_tieu'));
                            myWindow.document.write(
                                '<body onload="window.print();window.close();"><img alt="" src="' +
                                imgData +
                                '" style="width: calc(100% - 10px); border-radius: 4px; padding: 5px; box-shadow: 0px 2px 2px rgb(0 0 0 / 25%);" /></body>'
                            );
                            myWindow.document.close();
                            myWindow.focus();
                        } catch (err) {
                            Config.popup.show('ERROR', err);
                        }
                    }
                    setIsExportPDF(false);
                })
            ;
        }
    },[isExportPDF, stateNumYear, userSelected]);

    const loadCboPosition = () => {
        props.generalActions.getCboDuty2(error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        })
    };

    const loadPermission = () => {
        props.generalActions.getPermission(FormID, isPer => {
            setPermission(isPer || 0);
        });
    };

    const onLoadDataFilter = () => {
        if (!isESS) loadCboEmployees();
        loadDataOrgChart();
        loadGroupDuty();
        loadCboPosition();
    };

    const loadForm = () => {
        const {
            NumYear = 0,
            EmployeeID = '',
            OrgChartID = [],
            DutyGroupID = '',
            DutyID = '',
            IsExportExcel = 0,
        } = filterSearch;
        let param = {
            FormID,
            skip: filterSearchRef.current.skip,
            limit: filterSearchRef.current.limit,
            NumYear,
            EmployeeID,
            DutyGroupID,
            DutyID,
            IsExportExcel,
            IsESS: isESS,
        };

        if (OrgChartID.length) param.OrgChartID = OrgChartID;

        setLoadEmployees(true);
        props.w39F3040Actions.getFormData(param, (error, data) => {
            setLoadEmployees(false);
            if (error) {
                let message = error.message || Config.lang('DHR_Loi_chua_xac_dinh');
                Config.popup.show('INFO', message);
                setDataGrid(initData);
                return false;
            }
            if (data) {
                setDataGrid(data);
                if(!userSelected) onRowClick({data: data?.rows[0]});
            }
        });
    };

    const onChangePage = page => {
        filterSearchRef.current.skip = page * filterSearchRef.current.limit;
        loadForm();
    };

    const onChangePerPage = perPage => {
        filterSearchRef.current.skip = 0;
        filterSearchRef.current.limit= perPage;
        loadForm();
    };

    const loadGroupDuty = () => {
        const param = {
            Language: Config.language || '84',
        };

        props.w39F3040Actions.getCbGroupDuty(param, (error, data) => {
            setLoadEmployees(false);
            if (error) {
                Config.popup.show('INFO', error);
                setGroupDuty([]);
                return false;
            }
            if (data) {
                setGroupDuty(data);
            }
        });
    };

    const loadTreeList = () => {
        const { NumYear } = filterSearch;
        const param = {
            FormID,
            EmployeeID: userSelected?.EmployeeID,
            NumYear,
        };

        props.w39F3040Actions.loadTreeList(param, (error, data) => {
            if (error) {
                Config.popup.show('INFO', error);
                setTreeList(null);
                return false;
            }
            if (data) {
                const dataSource = _generateTreeList(data);
                const first = dataSource.find(i=>i.Level === 1);
                if(first) onFilter(first);
                setTreeList(dataSource);
            }
        });
    };

    const onView = data => {
        const { Level, SummaryDocID, Type, AppraisalDocID, ParentID, ObjectiveID } = data;

        let dataPopup = null;
        if (Level === 1) {
            dataPopup = {
                ...data,
                FormID: 'W39F3035',
                GoalPlanID: SummaryDocID,
                OrgChartID: '',
                EmployeeID: userSelected.EmployeeID,
            };
        } else if (Level === 2) {
            if (Type === 'KPI') {
                dataPopup = {
                    ...data,
                    FormID: 'W39F1011',
                    IsSave: 0,
                    Mode: 1,
                    ApproveLevel: 1,
                    EmployeeID: userSelected.EmployeeID,
                    FormPermission: 'W39F1010',
                };
            }
            if (Type === 'Goal') {
                dataPopup = {
                    ...data,
                    FormID: 'W39F3000',
                    GoalPlanID: AppraisalDocID,
                    EmployeeID: userSelected.EmployeeID,
                    isESS: isESS,
                };
            }
        } else if (Level >= 3) {
            if (Level === 3 && Type === 'KPI') {
                // find parent of item
                const parent = treeList?.find(item => item.ID === ParentID);
                dataPopup = {
                    ...data,
                    FormID: 'W39F1011',
                    IsSave: 0,
                    TransID: parent.TransID,
                    EvaluationVoucherID: parent.EvaluationVoucherID,
                    Mode: 1,
                    ApproveLevel: 1,
                    EmployeeID: userSelected.EmployeeID,
                    FormPermission: 'W39F1010',
                };
            }
            if ((Level === 3 && Type === 'Goal') || Level === 4) {
                dataPopup = {
                    ...data,
                    FormID: 'W39F3002',
                    GoalID: ObjectiveID,
                    Mode: 0,
                    Type,
                };
            }
        }

        dataPopup.FromPath = FormID;
        setPopupDetail(dataPopup);
    };

    const onFilter = item => {
        const { NumYear } = filterSearch;
        const { Level = 1, Type = '', LibraryGoalID = '' } = item || {};
        const param = {
            FormID,
            EmployeeID: userSelected?.EmployeeID,
            NumYear,
            Level,
            Type,
            LibraryGoalID,
        };

        props.w39F3040Actions.loadFilter(param, (error, data) => {
            let dataFilter = {};
            if (error) {
                Config.popup.show('ERROR', error);
                setDataChart(null);
                return false;
            } else if (data) {
                const dataDD = _.groupBy(data[1], 'Year');

                const arrYear = {};
                let next = 0;

                // Click vào từng node của Treelist đánh giá, gọi đổ nguồn lại Filter thuộc tính thể hiện chart:
                // @Level = 1 default ObjectID = @SummaryDocID của node click, nếu không có thì default dòng đầu tiên
                // @Level = 2 default ObjectID = @AppraisalDocID của node click, nếu không có thì default dòng đầu tiên
                // @Level  IN  (3,  4)  default  ObjectID  =  @ObjectiveID của  node  click, nếu  không  có  thì default dòng đầu tiên
                const conditionObjectID = new Map([
                    [1, 'SummaryDocID'],
                    [2, 'AppraisalDocID'],
                    [3, 'ObjectiveID'],
                    [4, 'ObjectiveID'],
                ]);

                do {
                    const year = moment().subtract(next, 'year').format('YYYY');
                    const vl = dataDD[year]?.find(i=> i.ObjectID === item[conditionObjectID.get(Level)]) || null;

                    if(vl){
                        dataFilter[year] = vl.ObjectID;
                    }
                    else if (dataDD[year]?.[0]) {
                        dataFilter[year] = dataDD[year]?.[0].ObjectID;
                    }

                    arrYear[year] = dataDD?.[year];
                    next++;
                } while (next < NumYear);

                setDataChart({ name: data[0]?.[0]?.ChartName || '', data: arrYear });
            }
            setDataDDFilter(dataFilter);
        });
    };

    const loadDataOrgChart = () => {
        props.generalActions.getOrgCharts({}, (error, data) => {
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data) {
                const dataSource = _generateDataOrg(data, 'OrgChartID', 'OrgChartParentID');
                setDataOrgChart(dataSource);
            }
        });
    };

    const _generateDataOrg = (dataSource = [], valueExpr, parentIdExpr) => {
        return dataSource.map(e => {
            if (e[valueExpr] && e[parentIdExpr] && e[valueExpr] === e[parentIdExpr]) {
                delete e[parentIdExpr];
                e.expanded = true;
            } else if (!e[parentIdExpr]) {
                e.expanded = true;
            }
            return e;
        });
    };

    const _generateTreeList = dataSource => {
        let data = dataSource[1].map(item => ({
            ...item,
            ID: item.SummaryDocID,
            ParentID: null,
        })) || [];
        dataSource[2].forEach((item, idx) => {
            data.push({
                ...item,
                ID: item.AppraisalDocID,
                ParentID: item.SummaryDocID,
                expand: idx === 0,
            });
        });
        dataSource[3].forEach((item, idx) => {
            if (item.Level === 3) {
                data.push({
                    ...item,
                    ParentID: item.AppraisalDocID,
                    ID: item.ObjectiveID,
                    expand: idx === 0,
                });
            } else if (item.Level === 4) {
                data.push({
                    ...item,
                    ParentID: item.GoalParentID,
                    ID: item.ObjectiveID,
                    expand: idx === 0,
                });
            }
        });
        return data;
    };

    const loadCboEmployees = isReset => {
        const { HostID, Type, skip, limit, strSearch: search } = filterCboEmployees.current;
        const param = {
            HostID,
            Type,
            FormID,
            skip,
            limit,
            search,
        };
        setCboEmployeeLoading(true);
        props.generalActions.getCboEmployees(param, (error, data) => {
            setCboEmployeeLoading(false);
            if (error) {
                Config.popup.show('ERROR', error);
                setDataCboEmployees(initData);
                return false;
            } else if (data) {
                const rows = data && data.rows ? data.rows : dataCboEmployees.rows;
                const total = data && data.total ? data.total : data.length;
                setDataCboEmployees({
                    rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                    total: total,
                });
            }
        });
    };

    const filterChange = (filterName = '', e) => {
        if (!e) return;
        let value = _.get(e, 'value', _.get(e, 'target.value', ''));
        if (filterName === 'NumYear') setStateNumYear(value);
        setFilterSearch({...filterSearch, [filterName]: value});
    };

    const renderFilters = () => {
        return (
            <Filter
                isUseDDCore={true}
                // disabled={loaded || dataLoading}
                placeholder={Config.lang('Noi_dung_can_tim_kiem')}
                onOpenLoaded={onLoadDataFilter}
                renderFilter={() => {
                    // const { DirectManagers, OrgChartID, ProjectID, EmployeeID } = filter;
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col sm={12} lg={6}>
                                        <Dropdown
                                            label={Config.lang('So_nam_thong_ke')}
                                            dataSource={[1, 2, 3, 4, 5]}
                                            defaultValue={filterSearch?.NumYear}
                                            viewType={'outlined'}
                                            className={'mgt10 mgb0'}
                                            onChange={e => filterChange('NumYear', e)}
                                        />
                                    </Col>
                                    <Col sm={12} lg={6}>
                                        <Dropdown
                                            clearAble
                                            className={'mgt10 mgb0'}
                                            dataSource={_.get(dataCboEmployees, 'rows', [])}
                                            total={_.get(dataCboEmployees, 'total', 0)}
                                            skip={filterCboEmployees.current.skip}
                                            limit={filterCboEmployees.current.limit}
                                            value={filterSearch?.EmployeeID}
                                            valueObjectDefault={
                                                isESS ? Config.getUser({ EmployeeID: filterSearch?.EmployeeID }) : null
                                            }
                                            readOnly={isESS.current}
                                            loading={cboEmployeeLoading}
                                            viewType={'outlined'}
                                            valueExpr={'EmployeeID'}
                                            keyExpr={'EmployeeName'}
                                            searchDelayTime={1000}
                                            label={Config.lang('Nhan_vien')}
                                            placeholder={Config.lang('Chon')}
                                            displayExpr={'{EmployeeID} - {EmployeeName}'}
                                            onChange={e => filterChange('EmployeeID', e)}
                                            onInput={e => {
                                                filterCboEmployees.current.strSearch = e.target.value;
                                                filterCboEmployees.current.skip = 0;
                                                loadCboEmployees(true);
                                            }}
                                            onLoadMore={e => {
                                                filterCboEmployees.current.skip = e.skip;
                                                filterCboEmployees.current.limit = e.limit;
                                                loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row className={'mgt10'}>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            dataSource={dataOrgChart ?? []}
                                            valueExpr={'OrgChartID'}
                                            displayExpr={'OrgName'}
                                            label={Config.lang('Co_cau_to_chuc')}
                                            keyExpr={'OrgName'}
                                            multiple
                                            onChange={e => filterChange('OrgChartID', e)}
                                            value={filterSearch?.OrgChartID || []}
                                            clearAble
                                            viewType={'outlined'}
                                            placeholder={Config.lang('Chon')}
                                            className={'mg0'}
                                        >
                                            <TreeView
                                                allowSearch
                                                dataSource={dataOrgChart ?? []}
                                                displayExpr={'OrgName'}
                                                id={'OrgChartID'}
                                                multiple
                                                multipleValueMode="single"
                                                onChange={e => {
                                                    filterChange('OrgChartID', e);
                                                }}
                                                parentID={'OrgChartParentID'}
                                                value={filterSearch?.OrgChartID || []}
                                                valueExpr={'OrgChartID'}
                                            />
                                        </Dropdown>
                                    </Col>
                                </Row>
                                <Row className={'mgt10'}>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            clearAble
                                            className={'mg0'}
                                            dataSource={groupDuty || []}
                                            value={filterSearch?.DutyGroupID}
                                            viewType={'outlined'}
                                            valueExpr={'DutyGroupID'}
                                            keyExpr={'DutyGroupName'}
                                            searchDelayTime={1000}
                                            label={Config.lang('Nhom_chuc_danh')}
                                            placeholder={Config.lang('Chon')}
                                            displayExpr={'{DutyGroupID} - {DutyGroupName}'}
                                            onChange={e => filterChange('DutyGroupID', e)}
                                        />
                                    </Col>
                                </Row>
                                <Row className={'mgt10'}>
                                    <Col xs={12}>
                                        <Dropdown dataSource={getCboPosition}
                                                  clearAble
                                                  viewType={'outlined'}
                                                  className={'mg0'}
                                                  valueExpr={"DutyID"}
                                                  displayExpr={"DutyName"}
                                                  label={Config.lang("Chuc_danh")}
                                                  value={filterSearch?.DutyID || ""}
                                                  onChange={e => filterChange("DutyID", e)}/>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={'display_row align-center valign-middle'}>
                                <Button
                                    size={'medium'}
                                    color={'primary'}
                                    viewType={'outlined'}
                                    label={Config.lang('Tim_kiem')}
                                    startIcon={'Search'}
                                    onClick={loadForm}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    const onRowClick = e => {
        setDataChart(null);
        setDataDDFilter({});

        let user = e.data;
        const cacheUser = Config.getUser({
            EmployeeID: user.EmployeeID,
        });

        // get info cache of the user selected
        if (cacheUser && cacheUser.UserPictureURL) user.UserPictureURL = cacheUser.UserPictureURL;
        setUserSelected(user);
    };

    const dataLineChart = useMemo(() => {
        const dataT = [];
        if (!dataChart?.data) return dataT;
        let label = [];
        let data = [];
        let dataEvaluationTypeName = [];
        Array(stateNumYear)
            .fill()
            .forEach((item, idx) => {
                const key = moment().subtract(idx, 'year').format('YYYY');
                const value = dataChart.data[key]?.find(item => item.ObjectID === dataDDFilter[key]);
                label.push(key);
                data.push(value?.Value || 0);
                dataEvaluationTypeName.push(value?.EvaluationTypeName || '');
            });
        return {label, data, dataEvaluationTypeName};
    }, [dataChart, dataDDFilter, stateNumYear]);

    const dataDD = useMemo(() => {
        const arrYear = [];
        let next = 0;
        do {
            const year = moment().subtract(next, 'year').format('YYYY');
            arrYear.push(year);
            next++;
        } while (next < stateNumYear);
        return arrYear;
    }, [stateNumYear]);

    const disabled = useMemo(()=>{
        return !dataChart || !userSelected;
    }, [userSelected, dataChart]);

    const exportPDF = async () => {
        setIsExportPDF(true);
    };

    const exportExcel = () => {
        const {
            NumYear = 0,
            OrgChartID = [],
            DutyGroupID = '',
            DutyID = '',
            EmployeeID = '',
        } = filterSearch;
        let param = {
            FormID,
            NumYear,
            EmployeeID,
            OrgChartID: OrgChartID || [],
            DutyGroupID,
            DutyID,
            IsESS: isESS,
            Stage: `${moment()
                .subtract(stateNumYear - 1, 'year')
                .format('YYYY')}-${moment().format('YYYY')}`
        };

        props.w39F3040Actions.exportExcel(param, (error, data)=>{
            if (error) {
                Config.popup2.show('ERROR', error);
                return false;
            }
            if(data) {
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = data.URL;
                a.download = data.fileName ? decodeURI(data.fileName) : "";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        })
    };

    const renderGrid = useMemo(()=>{
        return(
            <GridContainer
                style={{ borderRight: `1px solid ${category}`, minHeight:'100%' }}
                dataSource={dataGrid?.rows}
                totalItems={Number(dataGrid?.total)}
                itemPerPage={filterSearchRef.current.limit}
                skipPerPage={filterSearchRef.current.skip}
                showBorders={false}
                keyExpr={'EmployeeID'}
                selectedRowKey={userSelected?.EmployeeID}
                showColumnLines={false}
                showColumnHeaders={false}
                loading={loadEmployees}
                noDataText={Config.lang('Khong_co_du_lieu')}
                grouping={{ autoExpandAll: true }}
                pagerFullScreen={false}
                height={Config.getHeightGrid()}
                typeShort={true}
                onRowClick={onRowClick}
                onChangePage={onChangePage}
                onChangePerPage={onChangePerPage}
            >
                <Column width={'100%'} alignment={'left'} cellRender={e => <Item data={e.row.data} />} />
                <Column dataField={'DutyID'} visible={false} />
                <Column dataField={'DutyGroupID'} visible={false} />
                <Column dataField={'DutyGroupName'} visible={false} />
                <Column dataField={'DepartmentID'} visible={false} />
                <Column dataField={'DepartmentName'} visible={false} />
                <Column dataField={'EvaluationElementID'} visible={false} />
                <Column dataField={'EvaluationElementName'} visible={false} />
                <Column dataField={'Value'} visible={false} />
                <Column dataField={'EvaluationTypeID'} visible={false} />
                <Column dataField={'EvaluationTypeName'} visible={false} />
            </GridContainer>
        )
        // eslint-disable-next-line
    },[dataGrid, loadEmployees]);

    const detectHeight = (el) => {
        const main = el.getBoundingClientRect();
        let prevValue = JSON.stringify(main);
        const handle = setInterval(() => {
            let nextValue = JSON.stringify(main);
            if (nextValue.width === prevValue.width && nextValue.height === prevValue.height) {
                const {width, height} = main;
                setSizeChart({width, height});
                clearInterval(handle);
            } else {
                prevValue = nextValue;
            }
        }, 100);
    };

    const renderChart = useMemo(() => {
        return(
            <>
                <Typography type={'h3'}>{dataChart?.name || '---'}</Typography>
                <hr
                    width="100%"
                    style={{
                        borderBottom: `1px solid ${system}`,
                        margin: `${spacing(4)}px 0`,
                    }}
                />
                {!!dataLineChart?.label?.length && sizeChart?.height && (
                    <Line
                        width={sizeChart?.width && (sizeChart.width - 50)}
                        height={sizeChart?.height && (sizeChart.height - 80)}
                        options={{
                            legend: false,
                            hover: {
                                intersect: false
                            },
                            tooltips: {
                                callbacks: {
                                    label: (tooltipItem) => {
                                        return dataLineChart.dataEvaluationTypeName[tooltipItem.index];
                                    },
                                    title : () => null
                                }
                            }
                        }}
                        data={{
                            labels: dataLineChart.label,
                            datasets: [
                                {
                                    data: dataLineChart.data,
                                    fill: false,
                                    borderColor: warning,
                                    lineTension: 0
                                }
                            ],
                        }}
                    />
                )}
            </>
        )
    },[dataLineChart, sizeChart, dataChart]);

    return (
        <FormGroup className={`mgb0 ${classes.W39F3040}`}>
            <ActionToolbar title={Config.lang('Lich_su_danh_gia')}>
                <div className={'display_row valign-bottom'} style={{ width: '100%' }}>
                    <Button
                        startIcon={
                            <Icons name={'Sheet'} color={disabled ? disabledCL : 'success'} width={24} height={24} />
                        }
                        label={Config.lang('Xuat_excel')}
                        className={'mgr5'}
                        disabled={disabled}
                        viewType={'outlined'}
                        onClick={exportExcel}
                    />
                    <Button
                        startIcon={
                            <Icons name={'Pdf'} color={disabled ? disabledCL : 'danger'} width={24} height={24} />
                        }
                        label={Config.lang('Xuat_pdf')}
                        disabled={disabled}
                        viewType={'outlined'}
                        loading={!!isExportPDF}
                        onClick={exportPDF}
                    />
                </div>
            </ActionToolbar>
            <div className={'hidden'}>{renderFilters()}</div>
            <Row className={'display_row'}>
                <Col md={3} sm={3} xs={3} className={'pd0'} style={{minWidth: 350}}>
                    {renderGrid}
                </Col>
                <Col
                    md={9}
                    sm={9}
                    xs={9}
                    style={{ background: extrabutton, padding: `${spacing(4)}px 0` }}
                    id={'view-export-pdf'}
                    ref={viewExportPDF}
                >
                    <Row>
                        <Col md={12} sm={12} xs={12}>
                            <ActionToolbar allwaysTop={false} style={{ background: white }}>
                                <div className={'display_row align-center'}>
                                    <Icons name={'CalendarBlank'} color={dark} className={'mgr10'} />
                                    <Typography type={'h2'}>
                                        {`${Config.lang('Giai_doan')} ${moment()
                                            .subtract(stateNumYear - 1, 'year')
                                            .format('YYYY')} - ${moment().format('YYYY')}`}
                                    </Typography>
                                </div>
                            </ActionToolbar>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            margin: `${spacing(6)}px 0 0`,
                            display: 'flex',
                            gap: spacing(6),
                            maxHeight: isExportPDF ? null : 'calc(100vh - 180px)',
                        }}
                    >
                        <Col
                            md={5}
                            sm={5}
                            xs={5}
                            className={'mgt0'}
                            style={{
                                background: white,
                                padding: 0,
                                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                                borderRadius: spacing(),
                            }}
                        >
                            <div className={'display_row'} style={{
                                padding: `${spacing(4)}px`,
                            }}>
                                <Avatar
                                    width={72}
                                    height={72}
                                    src={userSelected?.UserPictureURL}
                                    readOnly
                                />
                                <div className={'mgl15'}>
                                    <Typography type={'t3'} className={'mgb5'} lineClamp={2} hoverTooltip>
                                        {userSelected?.EmployeeName || '---'}
                                    </Typography>
                                    <Typography type={'p1'} lineClamp={2} hoverTooltip>
                                        {userSelected?.DutyName || '---'}
                                    </Typography>
                                </div>
                            </div>
                            <hr width="100%" className={classes.line} />
                            <div className={classes.treeView}>
                                <TreeView
                                    dataSource={treeList || []}
                                    displayExpr={'OrgName'}
                                    id={'ID'}
                                    expand
                                    onChange={e => onFilter(e?.data)}
                                    expandIcon={<Icons name={'ArrowRight'} className={'mgl5'}/>}
                                    collapseIcon={<Icons name={'ArrowDown'} className={'mgl5'}/>}
                                    renderItem={e => <TreeViewItem isExportPDF={isExportPDF} data={e.data} onView={onView} />}
                                    parentID={'ParentID'}
                                    className={classes.treeView}
                                    valueExpr={'ID'}
                                />
                            </div>
                        </Col>
                        <Col
                            md={7}
                            sm={7}
                            xs={7}
                            className={'mgt0'}
                            style={{
                                gap: spacing(6),
                            }}
                        >
                            <Row
                                style={{
                                    padding: spacing(4),
                                    background: white,
                                    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                                    borderRadius: spacing(),
                                    marginBottom: spacing(6),
                                    maxHeight: isExportPDF ? '' : 282,
                                    overflow: 'auto',
                                }}
                            >
                                {dataDD?.map(key => {
                                    return (
                                        <Dropdown
                                            key={key}
                                            label={key}
                                            dataSource={dataChart?.data?.[key]}
                                            valueExpr={'ObjectID'}
                                            displayExpr={'ObjectName'}
                                            viewType={'outlined'}
                                            value={dataDDFilter?.[key] || null}
                                            onChange={e => {
                                                setDataDDFilter({ ...dataDDFilter, [key]: e.value });
                                            }}
                                        />
                                    );
                                })}
                            </Row>

                            <Row className={'display_row align-center mgb10'}>
                                <span className={classes.dot} />
                                <Typography type={'h3'}>{Config.lang('Ket_qua_tong_hop_hang_nam')}</Typography>
                            </Row>
                            <div
                                style={{
                                    padding: spacing(4),
                                    background: white,
                                    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                                    borderRadius: 4,
                                    height: 'calc(100vh - 510px)',
                                    margin: '0 -15px'
                                }}
                                ref={ el=>{
                                    if (!el || !dataLineChart?.label?.length || sizeChart?.height) return;
                                    detectHeight(el);
                                }}
                            >
                                {renderChart}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {popupDetail?.FormID === 'W39F3002' && (
                <Modal open={popupDetail?.FormID === 'W39F3002'} onClose={() => setPopupDetail(null)}>
                    <ModalHeader title={Config.lang('Thong_tin_ket_qua_then_chot')} />
                    <ModalBody>
                        <W39F3002 goalProps={popupDetail} key={popupDetail?.GoalID} mode={0} />
                    </ModalBody>
                </Modal>
            )}
            {popupDetail?.FormID === 'W39F1011' && (
                <Modal open={popupDetail?.FormID === 'W39F1011'} width={'100%'} onClose={() => setPopupDetail(null)}>
                    <ModalHeader />
                    <ModalBody className={'pdt15 pdb15'}>
                        <W39F1011 TransID={popupDetail.TransID} isPopup={popupDetail} />
                    </ModalBody>
                </Modal>
            )}
            {popupDetail?.FormID === 'W39F3000' && (
                <Modal open={popupDetail?.FormID === 'W39F3000'} width={'100%'} onClose={() => setPopupDetail(null)}>
                    <ModalHeader />
                    <ModalBody className={'pdt15 pdb15'}>
                        <W39F3000 TransID={popupDetail.TransID} isPopup={popupDetail} />
                    </ModalBody>
                </Modal>
            )}
            {popupDetail?.FormID === 'W39F3035' && (
                <Modal open={popupDetail?.FormID === 'W39F3035'} width={'100%'} onClose={() => setPopupDetail(null)}>
                    <ModalHeader />
                    <ModalBody className={'pdt15 pdb15'}>
                        <W39F3036 isPopup={popupDetail} />
                    </ModalBody>
                </Modal>
            )}
        </FormGroup>
    );
};

const TreeViewItem = memo(props => {
    const { data, onView, isExportPDF } = props;
    let value = '';
    let icon = null;
    let color = '';
    switch (data.Level) {
        case 1:
            value = data.SummaryDocName;
            icon = 'Folder';
            color = 'info';
            break;
        case 2:
            value = data.AppraisalDocName;
            icon = 'Locate';
            color = 'danger';
            break;
        case 3:
            value = data.ObjectiveName;
            icon = 'Star';
            color = 'warning';
            break;
        default:
            value = data.ObjectiveName;
            icon = 'StarOutline';
            color = 'success';
    }

    const classes = useStyle();
    return (
        <div className={`display_row align-center align-between ${classes.TreeViewItem}`}>
            <div className={'display_row align-center'}>
                <Icons viewBox name={icon} color={color} width={20} height={20} className={'mgr5'} />
                <Typography type={'p1'} lineClamp={!isExportPDF ? 1 : null} hoverTooltip>
                    {value}
                </Typography>
            </div>
            <ButtonIcon className={'View'} name={'View'} circular viewType={'text'} onClick={() => onView(data)} />
        </div>
    );
});

const Item = React.memo(props => {
    const { data } = props;
    const dataEmployee = Config.getUser({ EmployeeID: data?.EmployeeID });
    return (
        <Row
            className={'display_row'}
            style={{
                margin: `${spacing(3)}px ${spacing(-1)}px`,
            }}
        >
            <Col>
                <Avatar
                    clearAble={false}
                    width={40}
                    height={40}
                    readOnly
                    data={{
                        [Config.lang('Nhan_vien')]: `${dataEmployee?.EmployeeID || ""} - ${dataEmployee?.EmployeeName || ""}`,
                        [Config.lang('Co_cau_to_chuc')]: dataEmployee?.OrgChartName || "",
                        [Config.lang('Nhom_chuc_danh')]: data?.DutyGroupName || "",
                        [Config.lang('Chuc_danh')]: dataEmployee?.DutyName || "",
                        [Config.lang('Ngay_vao_lam')]: Config.convertDate(dataEmployee?.DateJoined, '', 'L'),
                    }}
                    hoverAble
                    className={'mgr15'}
                    src={dataEmployee?.UserPictureURL || ''}
                />
            </Col>
            <Col>
                <Typography
                    type={'p1'}
                    lineClamp={2}
                    hoverTooltip
                >{`${data.EmployeeID} - ${data.EmployeeName}`}</Typography>
                <Typography type={'p1'} color={sub} lineClamp={2} hoverTooltip>
                    {data.DutyName}
                </Typography>
            </Col>
        </Row>
    );
});

export default connect(
    state=>({
        getCboPosition: state.general.getCboDuty2
    }),
    dispatch => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        w39F3040Actions: bindActionCreators(w39F3040Actions, dispatch),
    }),
    null,
    { forwardRef: true }
)(W39F3040);
