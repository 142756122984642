/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 23/4/2021
 * @Example
 */

import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles(theme => ({
    container: {
        height: '100%',
        background: 'white',
        borderRadius: 4,
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
    },
    flex: {
        display: 'flex'
    },
    gap10: {
        gap: 10
    },
    gap5: {
        gap: 5
    },
    divCurrent: {
        padding: '12px 24px',
        borderBottom: '1px solid #D4D5D9',
    },
    flexWrap: {
        flexWrap: 'wrap'
    },
    rowDirection: {
        flexDirection: 'row'
    },
    columnDirection: {
        flexDirection: 'column'
    },
    commentCont: {
        padding: '0 24px',
        minHeight: 100,
        overflow: 'auto',
        height: 'calc(100% - 200px)'
    },
    inputComment: {
        padding: '0 24px 24px'
    },
    alignCenter: {
        alignItems: 'center'
    },
    divGroupCmtCont: {
        marginTop: 20,
        paddingTop: 30,
        position: 'relative',
        borderTop: '1px solid #7F828E'
    },
    groupDate: {
        position: 'absolute',
        top: -9,
        padding: '0 5px',
        background: 'white'
    },
    justifyCenter: {
        justifyContent: 'center'
    },
    alignItemsStart: {
        alignItems: 'flex-start'
    },
    alignSelfEnd: {
        alignSelf: 'flex-end'
    },
    divTextEditValue: {
        padding: 8,
        minWidth: 100,
        maxWidth: 250,
        wordBreak: 'break-word',
        whiteSpace: 'pre-line',
        '&:not(:focus-within):hover': {
            backgroundColor: '#ebecf0',
        },
        '&:focus-within': {
            backgroundColor: 'white',
            boxShadow: '0 0 0 2px #4c9aff',
        }
    },
    editComment: {
        color: '#151A30',
        background: '#A2CFFF',
    },
    fullWidth: {
        width: '100%'
    },
    comment: {
        maxWidth: '250px !important',
        padding: 8,
        background: '#E9EAF0',
    },
    bdr4: {
        borderRadius: 4,

    },
    iconTextEdit: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#ebecf0',
        width: 32,
        height: 32,
        borderRadius: 4,
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
        '&:not(:first-of-type)': {
            marginLeft: 5
        }
    },
    pointerCursor: {
        cursor: 'pointer',
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    divIconCont: {
        justifyContent: 'flex-end',
        position: 'absolute',
        zIndex: 2,
        marginTop: 5,
        right: 0
    },
    divTextEditCont: {
        position: 'relative',
    },
    textNoWrap: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));