import { Avatar, Typography } from 'diginet-core-ui/components';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import Config from '../../../../../config';
import W39F3002TextEdit from '../../W39F3002/utils/W39F3002TextEdit';
import { Edit } from 'diginet-core-ui/icons';
import * as W39F6000Actions from '../../../../../redux/W3X/W39F6000/W39F6000_actions';
import { useDispatch } from 'react-redux';

W39F6000Cmt.propTypes = {
    classes: PropTypes.object,
    comment: PropTypes.object,
    loadData: PropTypes.func,
    handleLoading: PropTypes.func
};

W39F6000Cmt.defaultProps = {
    classes: {},
    comment: {},
    loadData: null,
    handleLoading: null
};

function W39F6000Cmt(props) {
    const { classes, comment, loadData, handleLoading } = props;

    const currentUserID = useRef(Config?.profile?.UserID ?? '');
    const cmtRef = useRef(null);

    const dispatch = useDispatch();

    const isUser = comment.UserID === currentUserID.current;

    const renderInfo = () => {
        const time = moment(new Date(comment?.ECreateDate ?? '')).format('HH:mm')
        if (!isUser) {
            const user = Config.getUser({ UserID: comment?.UserID ?? '' });
            const splitName = user?.EmployeeName.split(' ');
            const name = splitName[splitName.length - 1];

            return <div className={`${classes.flex} ${classes.gap5} ${classes.alignCenter}`}>
                <Avatar
                    clearAble={false}
                    width={24}
                    height={24}
                    readOnly
                    outlined={'1px solid white'}
                    src={user?.UserPictureURL ?? ''}
                />
                <Typography type={'p3'} color={'secondary'}>{`${name}, ${time}`}</Typography>
            </div>;
        }
        return <Typography type={'p3'} color={'secondary'} className={`${classes.alignSelfEnd}`}>{`${time}`}</Typography>
    };

    const renderComment = () => {
        if (!isUser) {
            return <Typography type={'p2'} className={`${classes.comment} ${classes.bdr4}`} >{comment?.Comment ?? ''}</Typography>
        }
        return <div className={`${classes.flex} ${classes.gap5} ${classes.alignCenter}`}>
            {comment?.EUpdateDate && <Edit width={24} height={24} viewBox style={{ display: 'inline-flex' }} />}
            <W39F3002TextEdit ref={cmtRef} classes={classes} value={comment?.Comment ?? ''}
                className={`${classes.divTextEditValue} ${classes.editComment} ${classes.bdr4}`}
                onSave={(e) => handleUpdateCmt(e)} />
        </div >
    };

    const handleUpdateCmt = (newCmt) => {
        const params = {
            id: comment?.id ?? '',
            Comment: newCmt ?? ''
        };

        dispatch(W39F6000Actions.editComment(params, (err, dt) => {
            if (err) {
                const msg = err?.message ?? Config.lang('Loi_chua_xac_dinh');
                return Config.popup2.show('ERROR', msg)
            }
            if (dt?.Status) {
                return Config.notify.show('error', Config.lang('Sua_binh_luan_khong_thanh_cong'), 2000);
            }
            handleLoading(true);
            Promise.all([loadData(comment?.Key01 ?? '')]).then(() => {
                handleLoading(false);
            });
            return Config.notify.show('success', Config.lang('Sua_binh_luan_thanh_cong'), 2000);
        }));
    };

    return (
        <div className={`${classes.flex} ${classes.columnDirection} ${classes.gap5} ${isUser ? classes.alignSelfEnd : ''}`}>
            {renderInfo()}
            {renderComment()}
        </div>
    );
}

export default W39F6000Cmt;