/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 6/24/2022
 * @Example
 */

import { LoadPanel, TextArea } from "devextreme-react";
import { Column, MasterDetail, RequiredRule } from "devextreme-react/data-grid";
import {
    Accordion,
    AccordionDetails,
    AccordionGroup,
    AccordionSummary,
    Badge,
    Button,
    ButtonIcon,
    Checkbox,
    Col,
    Dropdown,
    FormGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    NumberInput,
    Radio,
    Row,
    Tooltip,
    TreeView,
    Typography,
} from "diginet-core-ui/components";
import { makeStyles } from "diginet-core-ui/theme";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../../../config";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W39F3060Actions from "../../../../redux/W3X/W39F3060/W39F3060_actions";
import CDN from "../../../CDN";
import PopoverShowHideCol from "../../../common/popover-show-hide-col";
import PopupAttachment from "../../../common/popup-attachment/PopupAttachment";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import GridActionBar from "../../../grid-container/grid-actionbar";
import GridContainer from "../../../grid-container/grid-container";
import HeadClick from "../../../grid-container/head-click";
import History from "../../../libs/history";
import ChooseModal from "../../../popup/popup-choose";

const useStyles = makeStyles(() => ({
    columnNoPadding: {
        paddingLeft: "0 !important",
        paddingRight: "0 !important",
    },
}));

const CountBadge = React.memo(props => {
    return (
        <Badge
            color={"default"}
            showZero
            content={props?.content || 0}
            style={{
                display: "flex",
                margin: "auto",
            }}
        />
    );
});

const initData = {
    rows: [],
    total: 0,
};
const initFormData = {
    DateFrom: null,
    DateTo: null,
    Employee: null,
    GoalMode: 0,
    GoalPlanID: "",
    IsAllEditKey: 0,
    IsAllEditObject: 0,
    IsBalanceKeyProportion: 0,
    IsBalanceObjectProportion: 0,
    IsCancelObject: 0,
    IsEditMethodKey: 0,
    IsEditMethodObject: 0,
    IsImptEditKey: 0,
    IsImptEditObject: 0,
    IsProportionEditObject: 0,
    OrgChartID: "",
    StatusID: null,
};
const initPopupAttachments = {
    open: false,
    data: [],
    info: {},
};

const rewriteActionName = {
    //rewrite api with name wrong
};

const dataTabsHideColumns = () => [
    {
        id: 0,
        title: Config.lang("Muc_tieu"),
    },
    {
        id: 1,
        title: Config.lang("KQTC"),
    },
];
const dataSourceHideColumns = () => [
    {
        caption: Config.lang("Don_vi_tinh"),
        dataField: "MeasureMethod",
        tabId: 0,
    },
    {
        caption: Config.lang("Mo_ta"),
        dataField: "Description",
        tabId: [0, 1],
    },
];

const initLoading = {
    loadForm: false,
    getTableTarget: false,
    getComputeMethod: false,
    getCboGoals: false,
    getCboEmployees: false,
    save: false,
};
const FormID = "W39F3060";

const W39F3060 = () => {
    //init..
    const classes = useStyles();
    const dispatch = useDispatch();
    const { dataCboStatus, dataCboComputeMethods, dataCboGoals } = useSelector(state => state?.W39F3060 || {});

    //state variables..
    const [openModalTargetGroup, setOpenModalTargetGroup] = useState(false);

    const [loading, _setLoading] = useState(initLoading);
    const [masterLoading, setMasterLoading] = useState(false);

    const [formData, _setFormData] = useState(initFormData);
    const [selectFromCat, setSelectFromCat] = useState(false);

    const [dataTargetGroups, setDataTargetGroups] = useState([]);
    const [dataCboEmployees, setDataCboEmployees] = useState(initData);

    // Show/Hide columns..
    const [dataHideColumns, setDataHideColumns] = useState(dataSourceHideColumns);
    const [dataGridMasterSHColumns, setDataGridMasterSHColumns] = useState(dataSourceHideColumns(0));
    const [dataGridDetailSHColumns, setDataGridDetailSHColumns] = useState(dataSourceHideColumns(1));

    const [dataSourceTargetTable, setDataSourceTargetTable] = useState([]);
    const [dataGroupTargets, setDataGroupTargets] = useState([]);
    const [dataSourceGoals, setDataSourceGoals] = useState([]);

    const [dataChangeGroup, setDataChangeGroup] = useState({
        open: false,
        data: {},
    });
    const [popupAttachments, setPopupAttachments] = useState({
        open: false,
        data: [],
        info: null,
    });
    const [dataOrgChart, setDataOrgChart] = useState([]);

    const [dataColumns, setDataColumns] = useState([]);

    const [permissions, setPermissions] = useState({});

    const [isLoadForm, setIsLoadForm] = useState(false);
    const [isDataUpdated, setIsDataUpdated] = useState(false);

    //Ref variables..
    const refLoading = useRef(loading);
    const refDataOld = useRef(null);
    const refFormData = useRef(initFormData);
    const refGridMaster = useRef({});
    const refGridDetail = useRef({});
    const refDataDefault = useRef({});
    const refAttachments = useRef({
        attachments: [],
        removedAttachment: [],
    });
    const refRemovedAttachments = useRef([]);

    const timerContentReadyMaster = useRef({});
    const timerContentReadyDetail = useRef({});
    const refContainer = useRef(null);
    const filter = useRef(formData);
    const filterCboEmployees = useRef({
        skip: 0,
        limit: 50,
        strSearch: "",
        search2: "",
    });
    const timerLoading = useRef(null);
    const gridUpdated = useRef({});
    const refTimerLoadForm = useRef(null);
    const cellEditing = useRef("");
    const selectedRangeGrid1 = useRef({});
    const selectedRangeGrid2 = useRef({});
    const refSelectFromCat = useRef(false);
    const dropdownRef = useRef(null);
    const flag = useRef({
        addKQTC: null,
        addKQTCWhenInsertRow: false
    });

    //init methods..
    const setLoading = (obj = {}) => {
        refLoading.current = { ...refLoading.current, ...obj };
        _setLoading(refLoading.current);
    };
    const setFormData = (obj = {}) => {
        refFormData.current = { ...refFormData.current, ...obj };
        _setFormData(refFormData.current);
    };
    const disabled = useMemo(() => !!Object.keys(loading).find(l => loading[l]), [loading]);

    /**
     * Compare two object (allow set new value to obj B)
     */
    const compareData = (obj, obj1, fields = "*", setToObj1 = false) => {
        if (fields === "*") return _.isEqual(obj, obj1);
        if (!fields || fields?.length <= 0) return null;
        if (!Array.isArray(fields)) fields = [fields];
        let result = true;
        for (let f of fields) {
            if (_.get(obj, f) !== _.get(obj1, f)) {
                result = false;
                if (setToObj1) _.set(obj1, f, obj[f]);
            }
        }
        return result;
    };

    useEffect(() => {
        loadPermission();
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (permissions.main > 0) {
            callApi("getTableTarget");
            callApi("getCboComputeMethods", { FormID });
            callApi("getCboStatus", { FormID: "W39F3002" });
            callApi("getOrgCharts", {}, null, generalActions);
            loadCboGoals();
            if (formData.GoalMode === 0) loadCboEmployees(true);
            const employee = Config.getUser({EmployeeID: Config.getHREmployeeID()});
            // const employee = Config.getUser({ EmployeeID: "%00003" });
            setFormData({ Employee: employee });
        }
        // eslint-disable-next-line
    }, [permissions.main]);
    useEffect(() => {
        filterCboEmployees.current.skip = 0;
        const _search = formData?.GoalMode === 1 ? formData?.OrgChartID : "";
        if (_search !== filterCboEmployees.current.search2) {
            filterCboEmployees.current.search2 = _search;
            loadCboEmployees(true);
            setFormData({ Employee: {} });
        }
        // eslint-disable-next-line
    }, [formData]);
    useEffect(() => {
        const isEqual = compareData(
            formData,
            filter.current,
            ["GoalMode", "OrgChartID", "StatusID", "Employee.EmployeeID", "GoalPlanID"],
            true
        );
        if (!isEqual) {
            if (refTimerLoadForm.current) clearTimeout(refTimerLoadForm.current);
            refTimerLoadForm.current = setTimeout(() => {
                loadForm();
            }, 300);
        }
        if (!compareData(formData, filter.current, ["OrgChartID", "Employee.EmployeeID"])) {
            loadCboGoals();
        }
        // eslint-disable-next-line
    }, [formData.StatusID, formData.Employee, formData.GoalPlanID]);
    useEffect(() => {
        refSelectFromCat.current = selectFromCat;
    }, [selectFromCat]);
    useEffect(() => {
        setDataGridMasterSHColumns(dataHideColumns.filter(d => [d.tabId].flat(1).includes(0)));
        setDataGridDetailSHColumns(dataHideColumns.filter(d => [d.tabId].flat(1).includes(1)));
    }, [dataHideColumns]);
    useEffect(() => {
        if (dataTargetGroups.length) setMasterLoading(true);
    }, [dataTargetGroups]);
    useEffect(() => {
        if (!formData?.GoalPlanID) return;
        callApi("getGroupTargets", {GoalPlanID: formData.GoalPlanID}, (data) => {
            if (data?.length) {
                data = data.map(d => {
                    if (!d.GroupGoalID) return d;
                    const item = dataGroupTargets.find(d1 => d1.GroupGoalID === d.GroupGoalID);
                    return { ...item, ...d };
                });
            }
            setDataGroupTargets(data);
        });
        // eslint-disable-next-line
    }, [formData.GoalPlanID]);

    const _generateDataOrg = (dataSource, valueExpr, parentIdExpr) => {
        return dataSource.map(e => {
            if (e[valueExpr] && e[parentIdExpr] && e[valueExpr] === e[parentIdExpr]) {
                delete e[parentIdExpr];
                e.expanded = true;
            } else if (!e[parentIdExpr]) {
                e.expanded = true;
            }
            return e;
        });
    };

    const callApi = (actionName, params, cb, typeAction) => {
        //default params...
        typeAction = typeAction ? typeAction : W39F3060Actions;
        if (rewriteActionName[actionName]) actionName = rewriteActionName[actionName];
        //return if haven't action
        if (!actionName || !typeAction[actionName]) return;

        const cbDone = (error, data) => {
            setLoading({ [actionName]: false });
            if (error) return Config.popup.show("ERROR", error) || false;
            if (data) {
                if (cb) cb(data);
                else {
                    switch (actionName) {
                        case "loadForm":
                            if (data?.Master) {
                                setFormData(data.Master);
                            }
                            if (data?.Goal) {
                                //Mapping group target selected..
                                const groupTargets = data.GroupGoal || dataGroupTargets || [];
                                setDataTargetGroups(data.Goal.map(g => {
                                    if (!g?.GroupGoalID) return g;
                                    const _group = groupTargets.find(gt => gt.GroupGoalID === g.GroupGoalID);
                                    return {
                                        GroupGoalID: g.GroupGoalID,
                                        GroupGoalName: _group?.GroupGoalName || "",
                                        allowSelect: !g?.Object || g?.Object?.length <= 0
                                    };
                                }));
                                setDataSourceGoals(data?.Goal || []);
                            }
                            if (data?.Reference) {
                                const _columns = data?.Reference || [];
                                let dynamicColumns = [];
                                _columns.forEach(col => {
                                    if (col.IsUsed === 1 && col.RefID) {
                                        dynamicColumns.push({
                                            caption: col?.Name || "",
                                            dataField: col?.RefID,
                                            tabId: col?.Type === "Object" ? 0 : 1,
                                        });
                                    }
                                });
                                setDataHideColumns([...dataSourceHideColumns()].concat(dynamicColumns));
                                setDataColumns(data?.Reference || []);
                            }
                            refDataOld.current = _.cloneDeep(data);
                            setIsLoadForm(true);
                            setIsDataUpdated(false);
                            break;
                        case "getTableTarget":
                            setFormData({GoalPlanID: data[0]?.GoalPlanID || ""});
                            // setFormData({ GoalPlanID: "W39MVKQ4QUMFJUT6FJQO" || "" }); //ex
                            setDataSourceTargetTable(data);
                            break;
                        case "getOrgCharts":
                            const _dataOrgChart = _generateDataOrg(data, "OrgChartID", "OrgChartParentID");
                            setDataOrgChart(_dataOrgChart);
                            break;
                        case "getCboComputeMethods":
                            const _computeMethod = data.find(d => d.ComputeMethod === "Manual");
                            refDataDefault.current = {
                                ...refDataDefault.current,
                                ComputeMethod: _computeMethod?.ComputeMethod || "",
                                ComputeMethodName: _computeMethod?.ComputeMethodName || "",
                            };
                            break;
                        case "getCboStatus":
                            const _status = data.find(d => d.StatusID === 0);
                            refDataDefault.current = {
                                ...refDataDefault.current,
                                Status: _status?.StatusID || 0,
                                StatusName: _status?.StatusName || "",
                            };
                            break;
                        default:
                            break;
                    }
                }
            }
        };
        setLoading({ [actionName]: true });
        if (params) {
            dispatch(typeAction[actionName](params, cbDone));
        } else {
            dispatch(typeAction[actionName](cbDone));
        }
    };

    //#region LOAD
    const loadPermission = () => {
        dispatch(
            generalActions.getPermission(["W39F3000", "W39F3003"], arrPer => {
                let _permissions = {};
                if (arrPer?.length > 0) {
                    for (let per of arrPer) {
                        if (per?.FormID) _permissions[per?.FormID] = per?.IsPermission || 0;
                    }
                }
                if (_permissions.hasOwnProperty("W39F3000")) _permissions.main = _permissions.W39F3000;
                setPermissions(_permissions);
            })
        );
    };
    const loadForm = () => {
        const {
            Employee: { EmployeeID },
            OrgChartID,
            StatusID,
            GoalMode,
            GoalPlanID,
        } = refFormData.current || {};

        const params = {
            FormID,
            GoalPlanID: GoalPlanID || "",
            EmployeeID: EmployeeID || "",
            OrgChartID: OrgChartID || "",
            GoalMode: GoalMode || 0,
            StatusID: StatusID || null,
        };
        callApi("loadForm", params);
    };
    const loadCboGoals = () => {
        const { OrgChartID, GoalMode, GoalPlanID, Employee } = refFormData.current || {};

        const params = {
            FormID,
            GoalPlanID: GoalPlanID || "",
            EmployeeID: Employee?.EmployeeID || "",
            OrgChartID: OrgChartID || "",
            GoalMode: GoalMode || 0,
        };
        callApi("getCboGoals", params);
    };
    const loadCboEmployees = isReset => {
        const { skip, limit, strSearch: search, search2 } = filterCboEmployees.current;

        const params = {
            FormID: "W39F3001",
            skip,
            limit,
            search,
            search2,
        };
        callApi(
            "getCboEmployees",
            params,
            data => {
                const rows = data && data.rows ? data.rows : dataCboEmployees.rows;
                const total = data && data.total ? data.total : data.length;
                setDataCboEmployees({
                    rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                    total: total,
                });
            },
            generalActions
        );
    };
    //#endregion

    const onReset = () => {
        setDataTargetGroups([]);
        setIsDataUpdated(false); // reset state grid is updated
        // reset key grid is updated
        Object.keys(gridUpdated.current).forEach(key => (gridUpdated.current[key] = false));
        // reset allowSelect for popup choose group goal..
        setDataGroupTargets(dataGroupTargets.map(d => ({ ...d, allowSelect: true })));
    };

    const onOpenTargetGroup = flag => {
        if (flag) {
            const _dataGroupTarget = dataGroupTargets.map((d, idx) => {
                const _group = dataTargetGroups.find(d1 => d1.GroupGoalID === d.GroupGoalID);
                const allowSelect = _group?.hasOwnProperty("allowSelect") ? _group.allowSelect : true;
                return {
                    ...d,
                    allowSelect: allowSelect ? !gridUpdated.current[d.GroupGoalID] : allowSelect
                };
            });
            setDataGroupTargets(_dataGroupTarget);
        }
        setOpenModalTargetGroup(!!flag);
    };

    const scrollToElement = el => {
        return new Promise(resolve => {
            if (!el) return;
            const position = parseInt(el.offsetTop - 56);
            let scrollMaxY = parseInt(document.documentElement.scrollHeight - document.documentElement.clientHeight);
            if (
                _.inRange(Math.round(window.scrollY), position - 1, position + 1) ||
                _.inRange(Math.round(window.scrollY), scrollMaxY - 1, scrollMaxY + 1)
            ) {
                resolve();
                return;
            }
            const scrollListener = evt => {
                if (typeof evt === "undefined") {
                    return;
                }

                const target = evt.currentTarget;
                scrollMaxY = parseInt(document.documentElement.scrollHeight - document.documentElement.clientHeight);
                if (
                    _.inRange(Math.round(target.scrollY), position - 1, position + 1) ||
                    _.inRange(Math.round(target.scrollY), scrollMaxY - 1, scrollMaxY + 1)
                ) {
                    target.removeEventListener("scroll", scrollListener);
                    resolve();
                }
            };

            window.addEventListener("scroll", scrollListener);

            // scroll to desired position (NB: this implementation works for
            // vertical scroll, but can easily be adjusted to horizontal
            // scroll as well)
            window.scrollTo({
                top: position,
                behavior: "smooth",
            });
        });
    };

    const onActions = (action, data) => {
        const { GroupGoalID } = data || {};
        scrollToElement(document.getElementById(GroupGoalID)).then(() => {
            let gridParent = refGridMaster.current[GroupGoalID]?.instance || null;
            let gridChild = null;
            switch (action) {
                case "addTarget":
                    if (GroupGoalID && gridParent) {
                        const _ds = gridParent.option("dataSource");
                        const _key = _ds.find(d => d.isNew)?.GoalID || "";
                        if (_key) {
                            const rIndex = gridParent.getRowIndexByKey(_key);
                            setTimeout(() => {
                                gridParent.editCell(rIndex, "GoalName");
                                gridParent.navigateToRow(_key);
                            }, 300);
                        }
                    }
                    break;
                case "addKQTC":
                    const { GoalID } = data || {};
                    if (GoalID) {
                        gridChild = refGridDetail.current[GoalID]?.instance || null;
                        if (GroupGoalID && gridParent && gridChild) {
                            const _ds = gridChild.option("dataSource");
                            const _key = _ds.find(d => d.isNew)?.GoalID || "";
                            if (_key) {
                                const rIndex = gridChild.getRowIndexByKey(_key);
                                gridParent.expandRow(GoalID);
                                gridChild.updateDimensions();
                                setTimeout(() => {
                                    gridChild.editCell(rIndex, "GoalName");
                                    gridChild.navigateToRow(_key);
                                }, 300);
                            }
                        }
                    }
                    break;
                default:
                    break;
            }
        });
    };

    const _confirmDataUpdated = async (cb, allowContinueWhenYes = false) => {
        if (isDataUpdated) {
            Config.popup.show(
                "YES_NO",
                Config.lang("Du_lieu_chua_duoc_luu_Ban_co_muon_luu_khong"),
                async () => {
                    await onSave();
                    if (allowContinueWhenYes && cb) {
                        setIsDataUpdated(false);
                        cb();
                    }
                },
                () => {
                    cb && cb();
                    setIsDataUpdated(false);
                }
            );
        } else {
            cb && cb();
        }
    };

    const handleChange = (key, e) => {
        if (!key || !e) return false;
        const value = _.get(e, "value", _.get(e, "target.value", ""));
        switch (key) {
            case "StatusID":
                setFormData({ [key]: value ? 2 : null });
                break;
            case "Employee":
                _confirmDataUpdated(() => {
                    const user = Config.getUser({ EmployeeID: e?.data?.EmployeeID });
                    if (formData?.GoalMode === 1 && formData?.OrgChartID && user?.OrgChartID) {
                        if (formData?.OrgChartID !== user?.OrgChartID) {
                            Config.popup.show(
                                "INFO",
                                Config.lang("Vui_long_chon_nhan_vien_thuoc_co_cau_to_chuc_da_chon")
                            );
                            return false;
                        }
                    }
                    setFormData({ [key]: e?.data || {} });
                    onReset();
                });
                break;
            case "GoalPlanID":
                _confirmDataUpdated(() => {
                    const { data, value } = e || {};
                    setFormData({
                        [key]: value,
                        DateFrom: data?.DateFrom || null,
                        DateTo: data?.DateTo || null,
                    });
                    onReset();
                });
                break;
            case "OrgChartID":
            case "GoalMode":
                _confirmDataUpdated(() => {
                    setFormData({ [key]: value });
                    onReset();
                });
                break;
            default:
                break;
        }
    };

    const changeTargetGroup = async e => {
        if (!e) return false;
        const { oldValue, newValue, data } = e || {};
        if (newValue !== oldValue) {
            e.cancel = true;
            Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_cap_nhat_nhom_muc_tieu?"), () => {
                const { GoalID } = data || {};
                let _dataSourceGoals = [...dataSourceGoals];
                if (oldValue && newValue && GoalID && oldValue !== newValue) {
                    const oldGroup = _dataSourceGoals.find(d => d.GroupGoalID === oldValue);
                    const itemOld = oldGroup?.Object ? oldGroup.Object.find(d => d.GoalID === GoalID) : null;
                    if (itemOld) {
                        _dataSourceGoals = _dataSourceGoals.map(d => {
                            if (d.GroupGoalID === oldValue) {
                                d.Object = d.Object.filter(f => f.GoalID !== data.GoalID);
                            }
                            if (d.GroupGoalID === newValue) {
                                d.Object.push(itemOld);
                            }
                            return d;
                        });
                    }
                    setDataSourceGoals(_dataSourceGoals);
                }
                setDataChangeGroup({
                    open: false,
                    data: null,
                });
            });
        }
    };

    const onDelete = e => {
        const { rowIndex = -1 } = e?.row || {};
        if (rowIndex > -1) {
            Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
                e.component.cellValue(rowIndex, "CheckMode", 2);
                e.component.saveEditData();
            });
        }
    };

    const _getSaveParams = async (withAttachments = true) => {
        let dataSource = [];
        if (refGridMaster.current) {
            for (let GroupGoalID of Object.keys(refGridMaster.current)) {
                const grid = refGridMaster.current[GroupGoalID]?.instance;
                const _data = grid.option("dataSource");
                let _item = [];
                for (let d of _data) {
                    if (d.isNew === 1) continue;
                    if (d.newAttachments?.length > 0 && withAttachments) {
                        let _fileUploaded = await _uploadFile(d.newAttachments, true);
                        _fileUploaded = _getPopUpAttachments(_fileUploaded);
                        if (_fileUploaded.length > 0) {
                            d.attachments = [...(d.attachments || []), ..._fileUploaded];
                            d.newAttachments = [];
                            const rowIndx = grid.getRowIndexByKey(d.GoalID);
                            if (grid) {
                                grid.cellValue(rowIndx, "attachments", d.attachments);
                                grid.cellValue(rowIndx, "newAttachments", []);
                            }
                        }
                    }
                    // for KeyResult (KQTC)
                    if (d.KeyResult?.length > 0) {
                        const gridChild = refGridDetail.current[d.GoalID]?.instance;
                        let _keyResults = [];
                        for (let child of d.KeyResult) {
                            if (child.isNew === 1) continue;
                            if (child?.newAttachments?.length > 0 && withAttachments) {
                                let _fileUploaded = await _uploadFile(child.newAttachments, true);
                                _fileUploaded = _getPopUpAttachments(_fileUploaded);
                                if (_fileUploaded.length > 0) {
                                    child.attachments = [...(child.attachments || []), ..._fileUploaded];
                                    child.newAttachments = [];
                                    const rowIndx = grid.getRowIndexByKey(child.GoalID);
                                    if (gridChild) {
                                        gridChild.cellValue(rowIndx, "attachments", child.attachments);
                                        gridChild.cellValue(rowIndx, "newAttachments", []);
                                    }
                                }
                            }
                            _keyResults.push(child);
                        }
                        d.KeyResult = _keyResults;
                    }
                    _item.push(d);
                }
                dataSource.push({
                    GroupGoalID,
                    Object: _item,
                });
            }
        }
        return dataSource;
    };

    const onSave = async () => {
        const { GoalMode, GoalPlanID, OrgChartID, Employee } = formData || {};
        setLoading({ save: true });
        const dataSource = await _getSaveParams();
        if (dataSource.length > 0) {
            const params = {
                FormID,
                GoalPlanID,
                EmployeeID: Employee?.EmployeeID || "",
                OrgChartID,
                GoalMode,
                arrGoal: JSON.stringify(dataSource),
            };
            callApi("save", params, async data => {
                if (data?.Status === 0) {
                    //Successfully
                    _removeCDN();
                    await saveHistory(data?.TransID, params);
                    setLoading({ save: false });
                    setIsDataUpdated(false);
                    loadForm();
                    Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                } else {
                    setLoading({ save: false });
                    Config.popup.show("INFO", data.Message);
                    return false;
                }
            });
        }
    };

    const _createDataMasterHistory = (dataMaster, goal, type = "Object") => {
        // type for "Object", "Key" case, but i only use "Object" (for get dynamic columns)
        // get name from data combo...
        const GoalPlanName =
            dataSourceTargetTable?.find(d => d.GoalPlanID === dataMaster?.GoalPlanID)?.GoalPlanName || "";
        const OrgChartName = dataOrgChart?.find(d => d.OrgCharID === dataMaster?.OrgChartID)?.OrgChartName || "";
        const GroupGoalName = dataGroupTargets?.find(d => d.GroupGoalID === goal?.GroupGoalID)?.GroupGoalName || "";
        const ComputeMethodName =
            goal?.ComputeMethodName ||
            dataCboComputeMethods?.find(d => d.ComputeMethod === goal?.ComputeMethod)?.ComputeMethodName ||
            "";
        const StatusName = goal?.StatusName || dataCboStatus?.find(d => d.Status === goal?.Status)?.StatusName || "";
        const { EmployeeID, EmployeeName } = dataMaster;
        // Structure master for require
        let master = {
            GoalMode: dataMaster?.GoalMode || 0,
            GoalPlanName: GoalPlanName,
            EmployeeID: `${dataMaster?.Employee?.EmployeeID || EmployeeID} - ${
                dataMaster?.Employee?.EmployeeName || EmployeeName
            }`,
            OrgChartName: OrgChartName,
            GroupGoalName: GroupGoalName,
            GoalName: goal?.GoalName || "",
            DateFrom: goal?.ValidDateFrom || null,
            DateTo: goal?.ValidDateTo || null,
            Proportion: goal?.Proportion || 0,
            MeasureMethod: goal?.MeasureMethod || "",
            ComputeMethodName: ComputeMethodName,
            StatusName: StatusName,
            Description: goal?.Description || "",
        };
        // empty master when param is wrong OR row is new (no data)
        //set data for dynamic columns..
        const _columns = dataColumns.filter(d => d.Type === type);
        for (let col of _columns) {
            if (col?.RefID && col?.IsUsed === 1) master[col.RefID] = goal?.[col.RefID] || "";
        }
        if (_.isEmpty(dataMaster) || _.isEmpty(goal) || goal.isNew === 1) master = {};
        //get data detail (with row is not new)
        let detail = (goal?.KeyResult || []).filter(d => d.isNew !== 1);
        return { master, detail };
    };
    const _getDataGoals = async () => {
        const groupNewGoals = await _getSaveParams();
        const groupOldGoals = refDataOld.current.Goal || [];
        return {
            newData: groupNewGoals?.flatMap(d => d?.Object || []) || [],
            oldData: groupOldGoals?.flatMap(d => d?.Object || []) || [],
        };
    };
    const saveHistory = async (ID, params) => {
        if (ID) {
            //Init captions..
            const captions = {
                GoalMode: "Loai",
                GoalPlanName: "Bang_muc_tieu",
                EmployeeID: "Nguoi_thuc_hien",
                OrgChartName: "Co_cau_to_chuc",
                GroupGoalName: "Nhom_muc_tieu",
                GoalName: "Ten_muc_tieu",
                DateFrom: "Ngay_bat_dau",
                DateTo: "Ngay_hoan_thanh",
                Proportion: "Trong_so",
                MeasureMethod: "Don_vi_tinh",
                ComputeMethodName: "Phuong_phap_do",
                StatusName: "Trang_thai",
                Description: "Mo_ta",
                StrObjectiveRef01: "Tham chiếu chuỗi 1",
                StrObjectiveRef02: "Tham chiếu chuỗi 2",
                StrObjectiveRef03: "Tham chiếu chuỗi 3",
                CoeffObjective01: "Hệ số 1",
                CoeffObjective02: "Hệ số 2",
                CoeffObjective03: "Hệ số 3",
                CoeffObjective04: "Hệ số 4",
                CoeffObjective05: "Hệ số 5",
            };
            const detailCaptions = {
                GoalName: "Ten_KQTC",
                DateFrom: "Ngay_bat_dau",
                DateTo: "Ngay_hoan_thanh",
                Proportion: "Trong_so",
                ComputeMethodName: "Phuong_phap_do",
                StatusName: "Trang_thai",
                Description: "Mo_ta",
                StrKeyRef01: "Tham chiếu chuỗi 1",
                StrKeyRef02: "Tham chiếu chuỗi 1",
                StrKeyRef03: "Tham chiếu chuỗi 1",
                NumKeyRef01: "Hệ số 1",
                NumKeyRef02: "Hệ số 2",
                NumKeyRef03: "Hệ số 3",
                NumKeyRef04: "Hệ số 4",
                NumKeyRef05: "Hệ số 5",
            };
            const data = params?.arrGoal ? JSON.parse(params.arrGoal) : [];
            if (data?.length > 0) {
                // history general...
                const _history = new History();
                const { newData, oldData } = await _getDataGoals();
                for (let _newData of newData) {
                    const newItem = _createDataMasterHistory(formData, _newData);
                    const oldGoal = oldData.find(d => d.GoalID === _newData.GoalID);
                    const oldItem = _createDataMasterHistory(refDataOld.current?.Master, oldGoal);
                    if (!newItem.master) continue;
                    const options = {
                        data: newItem.master,
                        captions: captions,
                        dataCompare: oldItem?.master || null,
                        action: _newData.CheckMode === 2 ? 3 : _newData.CheckMode,
                        ModuleID: "D39",
                        TransID: ID,
                        TransactionID: "W39F3060",
                        TransactionName: Config.lang("Tao_muc_tieu_va_KQTC_hoang_loat"),
                    };
                    const history = new History(options); //Init history
                    history.createDetailHistory(
                        "Danh_sach_KQTC",
                        newItem.detail,
                        oldItem.detail,
                        detailCaptions,
                        "GoalID",
                        null,
                        options
                    );
                    _history.import(history.get()); //import every history data to general history
                }
                if (_history.get().length > 0) {
                    await _history.save();
                }
            }
        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }
    };

    const renderHeaderColumns = (e, selectedRange) => {
        if (!e) return false;
        return <HeadClick selectedRange={selectedRange} dataHeaderCell={e} allowClick={true} />;
    };

    const renderButtonAction = useCallback(
        (key, e) => {
            const { data, GroupGoalID } = e || {};
            if (data.isNew === 1) {
                const cellElement = e.component.getCellElement(e.rowIndex, e.columnIndex);
                if (cellElement) cellElement.style.display = "none";
                return null;
            }
            return (
                <GridActionBar>
                    {key === "master" && (
                        <Tooltip title={Config.lang("Cap_nhat_nhom_muc_tieu")}>
                            <ButtonIcon
                                circular
                                size={"medium"}
                                viewType={"text"}
                                name={"Edit"}
                                disabled={formData?.IsAllEditObject === 0}
                                onClick={() =>
                                    setDataChangeGroup({
                                        open: true,
                                        data: { GroupGoalID, ...data },
                                    })
                                }
                            />
                        </Tooltip>
                    )}
                    <Tooltip title={Config.lang("Xoa")}>
                        <ButtonIcon
                            circular
                            size={"medium"}
                            viewType={"text"}
                            name={"Delete"}
                            disabled={formData?.IsCancelObject === 0}
                            onClick={() => onDelete(e)}
                        />
                    </Tooltip>
                </GridActionBar>
            );
        },
        // eslint-disable-next-line
        [formData?.IsAllEditObject, formData?.IsCancelObject]
    );

    const showAttachments = e => {
        const { data } = e || {};
        setPopupAttachments({
            open: true,
            data: (data?.attachments || []).concat(data?.newAttachments || []),
            info: e,
        });
    };
    const closeAttachments = () => {
        setPopupAttachments(initPopupAttachments);
    };
    const onChangePopupAttachments = e => {
        refAttachments.current.attachments = e?.attached || [];
        if (e.removedAttached && e.removedAttached.length > 0) {
            const _removedAttached = e.removedAttached.filter(a => !!a.AttachmentID);
            refAttachments.current.removedAttachment = [..._removedAttached];
        }
    };

    const onAddAttachments = async () => {
        const { info } = popupAttachments || {};
        if (!_.isEmpty(info) && info.component) {
            const { attachments, removedAttachment } = refAttachments.current || {};
            if (attachments.length) {
                const _attachments = attachments.filter(a => !a.AttachmentID);
                info.component.cellValue(info.rowIndex, "newAttachments", _attachments);
            }
            if (removedAttachment.length) {
                let _oldAttachments = info.component.cellValue(info.rowIndex, "attachments");
                const removeID = removedAttachment.map(a => a.AttachmentID);
                _oldAttachments = _oldAttachments.filter(a => !removeID.includes(a.AttachmentID));
                info.component.cellValue(info.rowIndex, "attachments", _oldAttachments);
                refRemovedAttachments.current = [...refRemovedAttachments.current, ...removedAttachment];
            }
            info.component.saveEditData();
            //reset ref attachment
            refAttachments.current = { attachments: [], removedAttachment: [] };
        }
        closeAttachments();
    };

    const _uploadFile = (files, isAsync, cb) => {
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    const _getPopUpAttachments = file => {
        const dataFile = file?.data?.paths || [];
        const listAttachments = Config.helpers.getFileInfomations(dataFile);
        let arrAttachment = [];
        listAttachments.forEach(att => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileName ? att.fileName.split(".").pop() : "",
            });
        });
        return arrAttachment;
    };

    const _removeCDN = () => {
        if (refRemovedAttachments.current?.length > 0) {
            refRemovedAttachments.current.forEach(e => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1],
                    };
                    CDN.removeFile(params);
                }
            });
            refRemovedAttachments.current = [];
        }
    };

    const exportExcel = () => {
        const dataGoal = refDataOld.current?.Goal || [];
        if (dataGoal.length > 0) {
            callApi("exportExcel", { Goal: JSON.stringify(dataGoal) }, data => {
                const link = document.createElement("a");
                link.href = data?.URL ?? "";
                link.download = data.fileName ? decodeURI(data.fileName) : "";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                Config.notify.show("success", Config.lang("Xuat_du_lieu_thanh_cong"), 3000);
            });
        } else {
            Config.popup.show("INFO", Config.lang("Khong_co_du_lieu"));
        }
    };

    const cellRenderNumber = (e, decimal) => {
        const number = e.displayValue ?? 0;
        if (Config.isEmpty(e.displayValue) && e.displayValue !== 0) return null;
        const formatter = new Intl.NumberFormat("en-US", {
            maximumFractionDigits: decimal,
        });
        const _number = formatter.format(String(number));
        return !_number || _number === "NaN" ? 0 : _number;
    };

    const _getFieldRequired = (type, include = "") => {
        let _fields = ["GoalName"];
        switch (type) {
            case "master":
                if (formData?.IsBalanceObjectProportion === 0) _fields.push("Proportion");
                break;
            case "detail":
                if (formData?.IsBalanceKeyProportion === 0) _fields.push("Proportion");
                break;
            default:
                break;
        }
        if (include) return _fields.includes(include);
        return _fields;
    };

    const onRowValidating = (e, type = "master") => {
        const { newData } = e || {};
        Object.keys(newData).map(d => {
            e.isValid = !(_getFieldRequired(type, d) && Config.isEmpty(newData[d], false));
            return d;
        });
    };

    const onAddKQTC = (data, GroupGoalID, timeout = 0) => {
        setTimeout(() => {
            onActions("addKQTC", {
                ...data,
                GroupGoalID,
            });
            flag.current.addKQTC = null;
            flag.current.addKQTCWhenInsertRow = false;
        }, timeout); //delay for orther processes
    };

    const getInitData = (type = "Object") => {
        return {
            GoalName: "",
            Proportion: 0,
            Type: type,
            GoalParentID: "",
            ValidDateFrom: null,
            ValidDateTo: null,
            DisplayOrder: 0,
            ComputeMethod: "Manual",
            ComputeMethodName: "Tự nhập",
            Status: 0,
            StatusName: "",
            Description: "",
            LibraryGoalID: "",
            ...refDataDefault.current,
        }
    };

    const renderMasterDetail = useCallback(
        args => {
            const { data: _data, GroupGoalID } = args || {};
            const { GoalID, isNew: parentIsNew } = _data || {};
            let dataSource = args.data.KeyResult || [];
            dataSource.map((d, idx) => {
                if (!d.hasOwnProperty("isNew")) d.isNew = 0;
                if (!d.hasOwnProperty("CheckMode")) d.CheckMode = 1;
                d.GoalParentID = GoalID;
                return d;
            });
            if (dataSource.length <= 0) {
                args.component.collapseRow(args.key);
            }
            if (parentIsNew) return null;

            const _dataColumns = dataColumns.filter(d => d.Type === "Key");

            return (
                <GridContainer
                    reference={ref => (refGridDetail.current[GoalID] = ref)}
                    listPerPage={[20, 40, 70, 100]}
                    loading={loading.gridLoading}
                    style={{
                        border: "none",
                        marginLeft: 64,
                        zIndex: 1,
                    }}
                    dataSource={dataSource}
                    pagerFullScreen={false}
                    wordWrapEnabled={true}
                    allowCellSelection={true}
                    sorting={{
                        mode: "none",
                    }}
                    editing={{
                        mode: "cell",
                        refreshMode: "reshape",
                        allowUpdating: true,
                        texts: { confirmDeleteMessage: "" },
                        startEditAction: "click",
                    }}
                    onEditingStart={e => {
                        const {
                            column: { dataField },
                            data,
                        } = e || {};
                        const rIndex = e.component.getRowIndexByKey(e.key);
                        if (rIndex > -1) e.component.selectRowsByIndexes([rIndex]);
                        cellEditing.current = dataField || ""; //detect column is editing..
                        if (data?.CheckMode === 1) {
                            let _cancel = false;
                            switch (dataField) {
                                case "GoalName":
                                case "StatusName":
                                    _cancel = formData?.IsImptEditKey === 0;
                                    break;
                                case "ValidDateFrom":
                                case "ValidDateTo":
                                case "Description":
                                    _cancel = formData?.IsAllEditKey === 0;
                                    break;
                                case "Proportion":
                                    _cancel = formData?.IsAllEditKey === 0 || formData?.IsBalanceKeyProportion === 1;
                                    break;
                                case "ComputeMethodName":
                                    _cancel = formData?.IsEditMethodKey === 0;
                                    break;
                                default:
                                    if (["StrKey", "NumKey"].find(s => dataField.includes(s))) {
                                        _cancel = formData?.IsAllEditKey === 0;
                                    }
                                    break;
                            }
                            e.cancel = _cancel;
                        }
                        if (!e.cancel) {
                            cellEditing.current = dataField || ""; //detect column is editing..
                        }
                    }}
                    onRowUpdating={e => {
                        const { newData, oldData } = e || {};
                        if (oldData?.isNew) {
                            const fieldRequired = _getFieldRequired("detail");
                            let isValid = true;
                            fieldRequired.map(d => {
                                const _isCheck = newData.hasOwnProperty(d)
                                    ? Config.isEmpty(newData[d])
                                    : Config.isEmpty(oldData[d]);
                                if (_isCheck) isValid = false;
                                return d;
                            });
                            if (isValid) {
                                e.newData.isNew = 0;
                                e.newData.addDone = true;
                            }
                        }
                    }}
                    onRowUpdated={e => {
                        const { data } = e || {};
                        if (data.addDone) {
                            const gridMaster = refGridMaster.current[GroupGoalID]?.instance || null;
                            if (gridMaster) {
                                const rIndex = gridMaster.getRowIndexByKey(GoalID);
                                const _count = gridMaster.cellValue(rIndex, "KeyResultCount");
                                gridMaster.cellValue(rIndex, "KeyResultCount", _count + 1);
                                e.data.addDone = false;
                            }
                        }
                        gridUpdated.current[GroupGoalID] = true;
                        cellEditing.current = ""; //remove detect column is editing..
                        if (!isDataUpdated) setIsDataUpdated(true);
                    }}
                    onRowRemoved={e => {
                        const gridMaster = refGridMaster.current[GroupGoalID]?.instance || null;
                        if (gridMaster) {
                            const rIndex = gridMaster.getRowIndexByKey(GoalID);
                            gridMaster.repaintRows([rIndex]);
                        }
                        if (!isDataUpdated) setIsDataUpdated(true);
                    }}
                    onContentReady={e => {
                        //auto add new row to grid
                        if (timerContentReadyDetail.current[GoalID])
                            clearTimeout(timerContentReadyDetail.current[GoalID]);
                        timerContentReadyDetail.current[GoalID] = setTimeout(() => {
                            const _dataSource = e.component.getDataSource();
                            if (_dataSource && _dataSource.isLoaded()) {
                                const filter = _dataSource.filter();
                                if (!filter) _dataSource.filter("CheckMode", "<>", 2);
                                const isExist = _dataSource.items().find(d => d.isNew);
                                if (!isExist) {
                                    const store = _dataSource.store();
                                    let item = {
                                        isNew: 1,
                                        isAdd: true,
                                        CheckMode: 0,
                                        ...getInitData("Key"),
                                        GoalParentID: GoalID,
                                        ValidDateFrom: _data?.ValidDateFrom || null,
                                        ValidDateTo: _data?.ValidDateTo || null,
                                        DisplayOrder: _dataSource.totalCount() + 1,
                                    };
                                    for (let col of _dataColumns) {
                                        if (col.IsUsed === 1 && col?.RefID) {
                                            item[col.RefID] = col.RefID.includes("Str") ? "" : 0;
                                        }
                                    }
                                    store.insert(item);
                                    _dataSource.reload().done(e => {
                                        if (masterLoading) {
                                            if (timerLoading.current) clearTimeout(timerLoading.current);
                                            timerLoading.current = setTimeout(() => {
                                                setMasterLoading(false);
                                            }, 200);
                                        }
                                    });
                                }
                            }
                            const el = e.component.element();
                            if (el) {
                                const isEditing = !!el.querySelector(".dx-datagrid-rowsview td.dx-editor-cell");
                                let scroll = args.component.getScrollable();
                                const elScroll = scroll.element();
                                if (elScroll && elScroll.classList) {
                                    if (isEditing)
                                        elScroll.classList.remove("dx-scrollable");
                                    else {
                                        elScroll.classList.add("dx-scrollable");
                                    }
                                }
                            }
                        }, 300);
                    }}
                    keyExpr={"GoalID"}
                    paging={{
                        enabled: false,
                    }}
                    onRowPrepared={e => {
                        if (e.rowType === "data") e.rowElement.style.whiteSpace = "pre-line";
                    }}
                    onEditorPreparing={e => {
                        if (e.editorType === "dxTextArea") {
                            e.editorOptions.autoResizeEnabled = true;
                            e.editorOptions.onInput = evt => {
                                const el = evt.element;
                                if (el && el.prevClientHeight && el.prevClientHeight !== el.clientHeight) {
                                    e.component.updateDimensions();
                                    e.component.focus(el);
                                }
                                el.prevClientHeight = el.clientHeight;
                            };
                        }
                        // e.component.updateDimensions();
                    }}
                    onCellSelectionChanged={e => {
                        if (e.selectedRange && e.selectedRange.rowType !== "header") {
                            Object.assign(selectedRangeGrid2.current, e.selectedRange);
                        }
                    }}
                    onCellDblClick={e => {
                        if (e?.column?.dataField === "showAttachments" && formData?.IsAllEditKey === 1) showAttachments(e);
                    }}
                    onRowValidating={e => onRowValidating(e, "detail")}
                    customizeColumns={columns => {
                        if (dataGridDetailSHColumns?.length > 0) {
                            dataGridDetailSHColumns.map(d => {
                                const visible = typeof d?.visible === "undefined" ? true : d?.visible;
                                const column = columns.find(
                                    col => col.dataField === d.dataField && col.visible !== visible
                                );
                                if (column) column.visible = d?.visible || false;
                                return d;
                            });
                        }
                    }}
                >
                    <Column dataField={"isNew"} visible={false} />
                    <Column dataField={"GoalParentID"} visible={false} />
                    <Column dataField={"GoalID"} visible={false} />
                    <Column dataField={"Type"} visible={false} />
                    <Column dataField={"CheckMode"} visible={false} />
                    <Column
                        caption={Config.lang("Hanh_dong")}
                        fixed={true}
                        width={96}
                        alignment="right"
                        allowEditing={false}
                        visible={Config.isMobile}
                        cellRender={e =>
                            renderButtonAction("detail", {
                                ...e,
                                GoalID: GoalID,
                                GroupGoalID,
                            })
                        }
                        fixedPosition={"right"}
                    />
                    <Column
                        caption={Config.lang("STT")}
                        dataField={"DisplayOrder"}
                        alignment={"center"}
                        width={100}
                        fixed
                        dataType={"number"}
                        sortOrder={"asc"}
                        editorOptions={{ min: 1 }}
                        calculateCellValue={data => (data.DisplayOrder < 1 ? 1 : data.DisplayOrder)}
                    />
                    <Column
                        dataField={"GoalName"}
                        caption={`${Config.lang("Ten")} KQTC`}
                        width={284}
                        fixed={true}
                        editorType={"dxTextArea"}
                    >
                        <RequiredRule message={Config.lang("Truong_nay_bat_buoc_nhap")} />
                    </Column>
                    <Column
                        dataField={"ValidDateFrom"}
                        dataType={"date"}
                        caption={Config.lang("Ngay_bat_dau")}
                        width={150}
                        format={"dd/MM/yyyy"}
                        allowCopying={true}
                        allowPasting={true}
                        allowEditing={formData?.IsAllEditKey === 1}
                        headerCellRender={e => renderHeaderColumns(e, selectedRangeGrid2.current)}
                    />
                    <Column
                        dataField={"ValidDateTo"}
                        dataType={"date"}
                        caption={Config.lang("Ngay_hoan_thanh")}
                        width={150}
                        format={"dd/MM/yyyy"}
                        allowCopying={true}
                        allowPasting={true}
                        allowEditing={formData?.IsAllEditKey === 1}
                        headerCellRender={e => renderHeaderColumns(e, selectedRangeGrid2.current)}
                    />
                    <Column
                        dataField={"Proportion"}
                        caption={Config.lang("Trong_so")}
                        width={100}
                        alignment={"right"}
                        cellRender={e => (e?.data?.Proportion || 0) + "%"}
                        editCellRender={e => {
                            return (
                                <NumberInput
                                    viewType={"none"}
                                    decimalDigit={4}
                                    allowZero
                                    defaultValue={0}
                                    value={e.value}
                                    className={"no-margin"}
                                    onChange={vl => e.setValue(vl.value)}
                                />
                            );
                        }}
                    >
                        <RequiredRule message={Config.lang("Truong_nay_bat_buoc_nhap")} />
                    </Column>
                    <Column dataField={"ComputeMethod"} visible={false} />
                    <Column
                        dataField={"ComputeMethodName"}
                        caption={Config.lang("Phuong_phap_do")}
                        width={250}
                        editCellRender={e => {
                            const {
                                data: { ComputeMethod, ComputeMethodName },
                            } = e || {};
                            const _defaultValue = ComputeMethod
                                ? {
                                      ComputeMethod,
                                      ComputeMethodName,
                                  }
                                : null;
                            const _dataCboComputeMethods = dataCboComputeMethods.filter(
                                d => d.ComputeMethod !== "BaseOnKey"
                            );
                            setTimeout(() => e.component.focus(e.cellElement), 100);
                            return (
                                <Dropdown
                                    dataSource={_dataCboComputeMethods}
                                    displayExpr={"ComputeMethodName"}
                                    valueExpr={"ComputeMethod"}
                                    viewType={"none"}
                                    className={"no-margin"}
                                    style={{ backgroundColor: "transparent" }}
                                    placeholder={Config.lang("Chon")}
                                    defaultValue={ComputeMethod || "Manual"}
                                    valueObjectDefault={_defaultValue}
                                    onChange={evt => {
                                        e.component.cellValue(e.rowIndex, "ComputeMethod", evt.data?.ComputeMethod);
                                        e.setValue(evt.data?.ComputeMethodName || "");
                                        setTimeout(() => e.component.focus(e.cellElement), 100);
                                    }}
                                />
                            );
                        }}
                    />
                    <Column dataField={"Status"} visible={false} />
                    <Column
                        dataField={"StatusName"}
                        caption={Config.lang("Trang_thai")}
                        width={200}
                        editCellRender={e => {
                            const {
                                data: { Status },
                            } = e || {};
                            const _dataCboStatus = dataCboStatus.filter(d => {
                                return formData?.IsCancelObject === 0 ? d.StatusID !== 2 : true;
                            });
                            setTimeout(() => e.component.focus(e.cellElement), 100);
                            return (
                                <Dropdown
                                    dataSource={_dataCboStatus}
                                    displayExpr={"StatusName"}
                                    valueExpr={"StatusID"}
                                    viewType={"none"}
                                    className={"no-margin"}
                                    style={{ backgroundColor: "transparent" }}
                                    placeholder={Config.lang("Chon")}
                                    defaultValue={Status}
                                    onChange={evt => {
                                        e.component.cellValue(e.rowIndex, "Status", evt.data?.StatusID);
                                        e.setValue(evt.data?.StatusName || "");
                                        setTimeout(() => e.component.focus(e.cellElement), 100);
                                    }}
                                />
                            );
                        }}
                    />
                    <Column
                        dataField={"Description"}
                        caption={Config.lang("Mo_ta")}
                        minWidth={250}
                        editorType={"dxTextArea"}
                    />
                    {_dataColumns &&
                        _dataColumns.map((col, idx) => {
                            if (col?.IsUsed === 0 || !col?.RefID) return null;
                            if (col.RefID.startsWith("Num")) {
                                return (
                                    <Column
                                        key={idx}
                                        dataField={col.RefID}
                                        caption={col?.Name}
                                        width={300}
                                        alignment={"right"}
                                        cellRender={e => cellRenderNumber(e, 4)}
                                        editCellRender={e => {
                                            setTimeout(() => e.component.updateDimensions(), 100);
                                            return (
                                                <NumberInput
                                                    viewType={"none"}
                                                    decimalDigit={4}
                                                    allowZero
                                                    thousandSeparator={","}
                                                    defaultValue={0}
                                                    value={e.value}
                                                    className={"no-margin"}
                                                    onChange={vl => e.setValue(vl.value)}
                                                />
                                            );
                                        }}
                                    />
                                );
                            } else {
                                return (
                                    <Column
                                        key={idx}
                                        dataField={col.RefID}
                                        caption={col?.Name}
                                        width={300}
                                        editorType={"dxTextArea"}
                                    />
                                );
                            }
                        })}
                    <Column dataField={"attachments"} visible={false} />
                    <Column
                        dataField={"showAttachments"}
                        caption={Config.lang("Dinh_kem")}
                        width={140}
                        allowEditing={false}
                        alignment={"center"}
                        cellRender={({ data }) => {
                            const oldAttachments = data?.attachments?.length || 0;
                            const newAttachments = data?.newAttachments?.length || 0;
                            return `(${oldAttachments + newAttachments})`;
                        }}
                    />
                    <Column dataField={"newAttachments"} visible={false} />
                    <Column
                        fixed={true}
                        width={96}
                        alignment="right"
                        allowEditing={false}
                        visible={!Config.isMobile}
                        cellRender={e =>
                            renderButtonAction("detail", {
                                ...e,
                                GoalID: GoalID,
                                GroupGoalID,
                            })
                        }
                        fixedPosition={"right"}
                    />
                </GridContainer>
            );
        },
        // eslint-disable-next-line
        [
            dataSourceGoals,
            masterLoading,
            formData,
            dataColumns,
            dataCboComputeMethods,
            loading,
            renderButtonAction,
            dataCboStatus,
            dataGridDetailSHColumns,
        ]
    );

    const renderGridMaster = useCallback(
        GroupGoalID => {
            let dataSource = dataSourceGoals.find(d => d.GroupGoalID === GroupGoalID);
            dataSource?.Object &&
                dataSource.Object.map((d, idx) => {
                    if (!d.hasOwnProperty("isNew")) d.isNew = 0;
                    if (!d.hasOwnProperty("CheckMode")) d.CheckMode = 1;
                    return d;
                });

            const _dataCboGoals = dataCboGoals.filter(d => d.GroupGoalID === GroupGoalID);
            const _dataColumns = dataColumns.filter(d => d.Type === "Object");

            return (
                <GridContainer
                    reference={ref => (refGridMaster.current[GroupGoalID] = ref)}
                    listPerPage={[20, 40, 70, 100]}
                    loading={loading.gridLoading}
                    style={{ border: "none" }}
                    dataSource={dataSource?.Object || []}
                    typePaging={"normal"}
                    keyExpr={"GoalID"}
                    hoverStateEnabled={true}
                    pagerFullScreen={false}
                    wordWrapEnabled={true}
                    elementAttr={{
                        style: `max-height: ${window.innerHeight - 245}px`,
                    }}
                    allowCellSelection={true}
                    sorting={{
                        mode: "none",
                    }}
                    // height={window.innerHeight - 269}
                    editing={{
                        mode: "cell",
                        refreshMode: "reshape",
                        allowUpdating: true,
                        texts: { confirmDeleteMessage: "" },
                        startEditAction: "click",
                    }}
                    onEditingStart={e => {
                        const {
                            column: { dataField },
                            data,
                        } = e || {};
                        const rIndex = e.component.getRowIndexByKey(e.key);
                        e.component.selectRowsByIndexes([rIndex]);
                        let _cancel = false;
                        if (data?.CheckMode === 1) {
                            switch (dataField) {
                                case "GoalName":
                                case "StatusName":
                                    _cancel = formData?.IsImptEditObject === 0;
                                    break;
                                case "ValidDateFrom":
                                case "ValidDateTo":
                                case "Description":
                                    _cancel = formData?.IsAllEditObject === 0;
                                    break;
                                case "Proportion":
                                    _cancel =
                                        formData?.IsProportionEditObject === 0 ||
                                        formData?.IsBalanceObjectProportion === 1;
                                    break;
                                case "ComputeMethodName":
                                    _cancel = formData?.IsEditMethodObject === 0;
                                    break;
                                default:
                                    break;
                            }
                        }
                        // for dynamic columns
                        if (["StrObjective", "CoeffObjective"].find(s => dataField.includes(s))) {
                            if (formData?.IsAllEditObject === 0) {
                                _cancel = true;
                            } else if (dataField.includes("CoeffObjective") && data?.LibraryGoalID) {
                                const _itemCbo = _dataCboGoals.find(d => d.GoalID === data.LibraryGoalID) || {};
                                const _value = _itemCbo.Coeff?.find(d => d.CoeffID === dataField);
                                if (_value) _cancel = _value?.IsUsed === 0;
                            }
                        }
                        e.cancel = _cancel;
                        if (!e.cancel) cellEditing.current = dataField || ""; //detect column is editing..
                    }}
                    onRowUpdating={e => {
                        const { newData, oldData } = e || {};
                        if (oldData?.isNew) {
                            const fieldRequired = _getFieldRequired("master");
                            let isValid = true;
                            fieldRequired.map(d => {
                                const _isCheck = newData.hasOwnProperty(d)
                                    ? Config.isEmpty(newData[d])
                                    : Config.isEmpty(oldData[d]);
                                if (_isCheck) isValid = false;
                                return d;
                            });
                            if (isValid) {
                                e.newData.isNew = 0;
                                e.newData.addDone = true; //For check addDone to addKQTC
                                document.getElementById("quantity" + GroupGoalID).innerText = e.component.totalCount();
                            }
                        }
                    }}
                    onRowUpdated={e => {
                        gridUpdated.current[GroupGoalID] = true;
                        cellEditing.current = ""; //remove detect column is editing..
                        if (!isDataUpdated) setIsDataUpdated(true);
                        if (flag.current.addKQTC) {
                            if (!e?.data?.addDone) {
                                onAddKQTC(flag.current.addKQTC, GroupGoalID, 500);
                            } else {
                                flag.current.addKQTCWhenInsertRow = true;
                            }
                        }
                    }}
                    onRowRemoved={e => {
                        document.getElementById("quantity" + GroupGoalID).innerText = e.component.totalCount() - 1;
                        if (!isDataUpdated) setIsDataUpdated(true);
                    }}
                    paging={{
                        enabled: false,
                    }}
                    onRowPrepared={e => {
                        if (e.rowType === "data") e.rowElement.style.whiteSpace = "pre-line";
                    }}
                    onContentReady={e => {
                        //auto add new row to grid
                        if (timerContentReadyMaster.current[GroupGoalID])
                            clearTimeout(timerContentReadyMaster.current[GroupGoalID]);
                        timerContentReadyMaster.current[GroupGoalID] = setTimeout(() => {
                            const _dataSource = e.component.getDataSource();
                            if (_dataSource && _dataSource.isLoaded()) {
                                const filter = _dataSource.filter();
                                if (!filter) _dataSource.filter("CheckMode", "<>", 2);
                                const isExist = _dataSource.items().find(d => d.isNew);
                                if (!isExist) {
                                    const store = _dataSource.store();
                                    let item = {
                                        isNew: 1,
                                        isAdd: true,
                                        CheckMode: 0,
                                        ...getInitData("Object"),
                                        MeasureMethod: "%",
                                        KeyResultCount: 0,
                                        ValidDateFrom: refFormData.current?.DateFrom || null,
                                        ValidDateTo: refFormData.current?.DateTo || null,
                                        DisplayOrder: _dataSource.totalCount() + 1,
                                        KeyResult: [],
                                    };
                                    for (let col of _dataColumns) {
                                        if (col.IsUsed === 1 && col?.RefID) {
                                            item[col.RefID] = col.RefID.includes("Str") ? "" : 0;
                                        }
                                    }
                                    store.insert(item);
                                    _dataSource.reload().done(() => {
                                        if (flag.current.addKQTC && flag.current.addKQTCWhenInsertRow) {
                                            onAddKQTC(flag.current.addKQTC, GroupGoalID, 500);
                                        }
                                    });
                                }
                            }
                            if (masterLoading) {
                                if (timerLoading.current) clearTimeout(timerLoading.current);
                                timerLoading.current = setTimeout(() => {
                                    setMasterLoading(false);
                                }, 300); //delay for waiting all done loading
                            }
                        }, 500); // delay for contentReady is final
                    }}
                    onEditorPreparing={e => {
                        if (e.editorType === "dxTextArea") {
                            e.editorOptions.autoResizeEnabled = true;
                            e.editorOptions.onInput = evt => {
                                const el = evt.element;
                                if (el && el.prevClientHeight !== el.clientHeight) {
                                    e.component.updateDimensions();
                                }
                                el.prevClientHeight = el.clientHeight;
                            };
                        }
                        // e.component.updateDimensions();
                    }}
                    onKeyDown={e => {
                        if (["GoalName"].includes(cellEditing.current) && e.event.keyCode === 13) e.handled = true;
                    }}
                    onCellSelectionChanged={e => {
                        if (e.selectedRange && e.selectedRange.rowType !== "header") {
                            Object.assign(selectedRangeGrid1.current, e.selectedRange);
                        }
                    }}
                    onCellDblClick={e => {
                        if (e?.column?.dataField === "showAttachments" && formData?.IsAllEditObject === 1) showAttachments(e);
                    }}
                    onRowValidating={e => onRowValidating(e, "master")}
                    customizeColumns={columns => {
                        if (dataGridMasterSHColumns?.length > 0) {
                            dataGridMasterSHColumns.map(d => {
                                const visible = typeof d?.visible === "undefined" ? true : d?.visible;
                                const column = columns.find(
                                    col => col.dataField === d.dataField && col.visible !== visible
                                );
                                if (column) column.visible = d?.visible || false;
                                return d;
                            });
                        }
                    }}
                >
                    <MasterDetail
                        enabled={true}
                        autoExpandAll
                        render={e =>
                            renderMasterDetail({
                                ...e,
                                GroupGoalID,
                            })
                        }
                    />
                    <Column dataField={"GoalParentID"} visible={false} />
                    <Column dataField={"isNew"} visible={false} sortOrder={"asc"} />
                    <Column dataField={"CheckMode"} visible={false} />
                    <Column dataField={"Type"} visible={false} />
                    <Column
                        caption={Config.lang("Hanh_dong")}
                        fixed={true}
                        width={96}
                        alignment="right"
                        allowEditing={false}
                        visible={Config.isMobile}
                        cellRender={e =>
                            renderButtonAction("master", {
                                ...e,
                                GroupGoalID,
                            })
                        }
                        fixedPosition={"right"}
                    />
                    <Column
                        caption={""}
                        alignment={"center"}
                        width={56}
                        fixed
                        allowEditing={false}
                        cellRender={e => {
                            const { data } = e || {};
                            if (data.isNew || permissions.main < 2) return null;
                            return (
                                <Tooltip title={Config.lang("Them") + " KQTC"} style={{ margin: "auto" }}>
                                    <ButtonIcon
                                        name={"AddOutline"}
                                        color={"primary"}
                                        circular
                                        size={"medium"}
                                        style={{ display: "flex" }}
                                        viewType={"ghost"}
                                        onClick={async () => {
                                            if (cellEditing.current) {
                                                flag.current.addKQTC = data;
                                                e.component.saveEditData();
                                            } else {
                                                onAddKQTC(data, GroupGoalID);
                                            }
                                        }}
                                    />
                                </Tooltip>
                            );
                        }}
                    />
                    <Column dataField={"GoalID"} visible={false} />
                    <Column dataField={"LibraryGoalID"} visible={false} />
                    <Column
                        caption={Config.lang("STT")}
                        dataField={"DisplayOrder"}
                        alignment={"center"}
                        width={100}
                        fixed
                        dataType={"number"}
                        sortOrder={"asc"}
                        editorOptions={{ min: 1 }}
                        calculateCellValue={data => (data.DisplayOrder < 1 ? 1 : data.DisplayOrder)}
                    />
                    <Column
                        dataField={"GoalName"}
                        caption={Config.lang("Ten_muc_tieu")}
                        width={250}
                        fixed={true}
                        editCellRender={e => {
                            const {
                                data: { GoalName, LibraryGoalID },
                            } = e || {};
                            const _defaultValue = LibraryGoalID && GoalName
                                ? {
                                    GoalID: LibraryGoalID,
                                    GoalName,
                                }
                                : null;
                            if ((!GoalName && refSelectFromCat.current) || LibraryGoalID) {
                                setTimeout(() => e.component.focus(e.cellElement), 100);
                                return (
                                    <Dropdown
                                        ref={dropdownRef}
                                        dataSource={_dataCboGoals}
                                        displayExpr={"GoalName"}
                                        valueExpr={"GoalID"}
                                        viewType={"none"}
                                        className={"no-margin"}
                                        style={{ backgroundColor: "transparent" }}
                                        placeholder={Config.lang("Chon")}
                                        defaultValue={LibraryGoalID}
                                        valueObjectDefault={_defaultValue}
                                        onChange={evt => {
                                            e.component.cellValue(e.rowIndex, "LibraryGoalID", evt.data?.GoalID);
                                            e.setValue(evt.data?.GoalName || "");
                                            setTimeout(() => e.component.focus(e.cellElement), 100);
                                        }}
                                    />
                                );
                            } else {
                                if (LibraryGoalID) e.component.cellValue(e.rowIndex, "LibraryGoalID", "");
                                return (
                                    <TextArea
                                        defaultValue={GoalName}
                                        autoResizeEnabled
                                        onInput={evt => {
                                            const el = evt.element;
                                            if (el && el.prevClientHeight !== el.clientHeight) {
                                                e.component.updateDimensions();
                                            }
                                            el.prevClientHeight = el.clientHeight;
                                        }}
                                        onValueChanged={evt => {
                                            e.component.cellValue(e.rowIndex, "LibraryGoalID", "");
                                            e.setValue(evt.value);
                                        }}
                                    />
                                );
                            }
                        }}
                    >
                        <RequiredRule message={Config.lang("Truong_nay_bat_buoc_nhap")} />
                    </Column>
                    <Column
                        caption={""}
                        dataField={"KeyResultCount"}
                        width={30}
                        fixed={true}
                        allowEditing={false}
                        cssClass={classes.columnNoPadding}
                        alignment={"center"}
                        calculateCellValue={data => {
                            const { KeyResult = [] } = data || {};
                            data.KeyResultCount = KeyResult.filter(d => !d.isNew)?.length || 0;
                            return data.KeyResultCount;
                        }}
                        cellRender={e => {
                            const { KeyResultCount = 0 } = e?.data || {};
                            return <CountBadge content={KeyResultCount} />;
                        }}
                    />
                    <Column
                        dataField={"ValidDateFrom"}
                        dataType={"date"}
                        caption={Config.lang("Ngay_bat_dau")}
                        width={150}
                        format={"dd/MM/yyyy"}
                        allowCopying={true}
                        allowPasting={true}
                        allowEditing={formData?.IsAllEditObject === 1}
                        headerCellRender={e => renderHeaderColumns(e, selectedRangeGrid1.current)}
                    />
                    <Column
                        dataField={"ValidDateTo"}
                        dataType={"date"}
                        caption={Config.lang("Ngay_hoan_thanh")}
                        width={150}
                        format={"dd/MM/yyyy"}
                        allowCopying={true}
                        allowPasting={true}
                        allowEditing={formData?.IsAllEditObject === 1}
                        headerCellRender={e => renderHeaderColumns(e, selectedRangeGrid1.current)}
                    />
                    <Column
                        dataField={"Proportion"}
                        caption={Config.lang("Trong_so")}
                        width={100}
                        cellRender={e => (e?.data?.Proportion || 0) + "%"}
                        editCellRender={e => {
                            return (
                                <NumberInput
                                    viewType={"none"}
                                    decimalDigit={4}
                                    allowZero
                                    defaultValue={0}
                                    value={e.value}
                                    className={"no-margin"}
                                    onChange={vl => e.setValue(vl.value)}
                                />
                            );
                        }}
                    >
                        <RequiredRule message={Config.lang("Truong_nay_bat_buoc_nhap")} />
                    </Column>
                    <Column
                        dataField={"MeasureMethod"}
                        caption={Config.lang("Don_vi_tinh")}
                        width={100}
                    />
                    <Column dataField={"ComputeMethod"} visible={false} />
                    <Column
                        dataField={"ComputeMethodName"}
                        caption={Config.lang("Phuong_phap_do")}
                        width={250}
                        editCellRender={e => {
                            const {
                                data: { ComputeMethod, ComputeMethodName },
                            } = e || {};
                            const _defaultValue = ComputeMethod
                                ? {
                                      ComputeMethod,
                                      ComputeMethodName,
                                  }
                                : null;
                            const _dataCboComputeMethods = dataCboComputeMethods.filter(
                                d => d.ComputeMethod !== "Formula"
                            );
                            setTimeout(() => e.component.focus(e.cellElement), 100);
                            return (
                                <Dropdown
                                    dataSource={_dataCboComputeMethods}
                                    displayExpr={"ComputeMethodName"}
                                    valueExpr={"ComputeMethod"}
                                    viewType={"none"}
                                    className={"no-margin"}
                                    style={{ backgroundColor: "transparent" }}
                                    placeholder={Config.lang("Chon")}
                                    defaultValue={ComputeMethod || "Manual"}
                                    valueObjectDefault={_defaultValue}
                                    onChange={evt => {
                                        e.component.cellValue(e.rowIndex, "ComputeMethod", evt.data?.ComputeMethod);
                                        e.setValue(evt.data?.ComputeMethodName || "");
                                        setTimeout(() => e.component.focus(e.cellElement), 100);
                                    }}
                                />
                            );
                        }}
                    />
                    <Column dataField={"Status"} visible={false} />
                    <Column
                        dataField={"StatusName"}
                        caption={Config.lang("Trang_thai")}
                        width={200}
                        editCellRender={e => {
                            const {
                                data: { Status },
                            } = e || {};
                            const _dataCboStatus = dataCboStatus.filter(d => {
                                return formData?.IsCancelObject === 0 ? d.StatusID !== 2 : true;
                            });
                            setTimeout(() => e.component.focus(e.cellElement), 100);
                            return (
                                <Dropdown
                                    dataSource={_dataCboStatus}
                                    displayExpr={"StatusName"}
                                    valueExpr={"StatusID"}
                                    viewType={"none"}
                                    className={"no-margin"}
                                    style={{ backgroundColor: "transparent" }}
                                    placeholder={Config.lang("Chon")}
                                    defaultValue={Status || 0}
                                    onChange={evt => {
                                        e.component.cellValue(e.rowIndex, "Status", evt.data?.StatusID);
                                        e.setValue(evt.data?.StatusName || "");
                                        setTimeout(() => e.component.focus(e.cellElement), 100);
                                    }}
                                />
                            );
                        }}
                    />
                    <Column
                        dataField={"Description"}
                        caption={Config.lang("Mo_ta")}
                        minWidth={250}
                        editorType={"dxTextArea"}
                    />
                    {_dataColumns &&
                        _dataColumns.map((col, idx) => {
                            if (col?.IsUsed === 0 || !col?.RefID) return null;
                            if (col.RefID.startsWith("Coeff")) {
                                return (
                                    <Column
                                        key={idx}
                                        dataField={col.RefID}
                                        caption={col?.Name}
                                        width={300}
                                        alignment={"right"}
                                        cellRender={e => cellRenderNumber(e, 4)}
                                        editCellRender={e => {
                                            return (
                                                <NumberInput
                                                    viewType={"none"}
                                                    decimalDigit={4}
                                                    allowZero
                                                    thousandSeparator={","}
                                                    defaultValue={0}
                                                    value={e.value}
                                                    className={"no-margin"}
                                                    onChange={vl => e.setValue(vl.value)}
                                                />
                                            );
                                        }}
                                    />
                                );
                            } else {
                                return (
                                    <Column
                                        key={idx}
                                        dataField={col.RefID}
                                        caption={col?.Name}
                                        width={300}
                                        editorType={"dxTextArea"}
                                    />
                                );
                            }
                        })}
                    <Column dataField={"attachments"} visible={false} />
                    <Column
                        dataField={"showAttachments"}
                        caption={Config.lang("Dinh_kem")}
                        width={140}
                        allowEditing={false}
                        alignment={"center"}
                        cellRender={({ data }) => {
                            const oldAttachments = data?.attachments?.length || 0;
                            const newAttachments = data?.newAttachments?.length || 0;
                            return `(${oldAttachments + newAttachments})`;
                        }}
                    />
                    <Column dataField={"newAttachments"} visible={false} />
                    <Column
                        fixed={true}
                        alignment="right"
                        width={96}
                        allowEditing={false}
                        visible={!Config.isMobile}
                        cellRender={e =>
                            renderButtonAction("master", {
                                ...e,
                                GroupGoalID,
                            })
                        }
                        fixedPosition={"right"}
                    />
                </GridContainer>
            );
        },
        // eslint-disable-next-line
        [
            dataSourceGoals,
            masterLoading,
            formData,
            dataColumns,
            dataCboComputeMethods,
            dataCboGoals,
            dataCboStatus,
            loading,
            renderMasterDetail,
            renderButtonAction,
            permissions.main,
            dataGridMasterSHColumns,
            flag.current.addKQTC
        ]
    );

    const renderContents = useMemo(() => {
        return (
            dataTargetGroups &&
            dataTargetGroups.map((d, idx) => {
                const _data = dataSourceGoals.find(d2 => d2.GroupGoalID === d.GroupGoalID);
                const _count = _data?.Object ? _data?.Object.filter(d2 => !d2.isNew).length : 0;
                return (
                    <Accordion key={idx} id={d.GroupGoalID} expand={true}>
                        <AccordionSummary>
                            <span style={{ color: d.Color }}>
                                {d.GroupGoalName}&nbsp;(<span id={"quantity" + d.GroupGoalID}>{_count}</span>)
                            </span>
                            {permissions.main > 1 && (
                                <Tooltip title={Config.lang("Them_muc_tieu")}>
                                    <ButtonIcon
                                        name={"AddFilled"}
                                        color={"primary"}
                                        circular
                                        size={"medium"}
                                        className={"mgl2x"}
                                        viewType={"ghost"}
                                        onClick={() => onActions("addTarget", d)}
                                    />
                                </Tooltip>
                            )}
                        </AccordionSummary>
                        <AccordionDetails
                            style={{
                                paddingLeft: 0,
                                paddingRight: 0,
                            }}
                        >
                            {renderGridMaster(d.GroupGoalID)}
                            {permissions.main > 1 && (
                                <Button
                                    size={"tiny"}
                                    color={"primary"}
                                    className={"mgt2x mgl4x"}
                                    viewType={"outlined"}
                                    startIcon={"AddOutline"}
                                    label={Config.lang("Them_muc_tieu")}
                                    onClick={() => onActions("addTarget", d)}
                                />
                            )}
                        </AccordionDetails>
                    </Accordion>
                );
            })
        );
        // eslint-disable-next-line
    }, [dataTargetGroups, dataSourceGoals, dataGridMasterSHColumns, dataColumns]);

    return (
        <div>
            <ChooseModal
                open={openModalTargetGroup}
                keyExpr={"GroupGoalID"}
                selected={dataTargetGroups}
                dataSource={dataGroupTargets}
                title={Config.lang("Chon_nhom_muc_tieu")}
                onClose={() => onOpenTargetGroup(false)}
                onChoosed={e => {
                    const { selectedRowsData } = e || {};
                    if (selectedRowsData?.length) setMasterLoading(true);
                    let _dataSourceGoals = [...dataSourceGoals];
                    for (let sd of selectedRowsData) {
                        const isExist = dataSourceGoals.find(d => d.GroupGoalID === sd.GroupGoalID);
                        if (!isExist) {
                            _dataSourceGoals.push({
                                GroupGoalID: sd.GroupGoalID,
                                Object:      []
                            });
                        }
                    }
                    setDataSourceGoals([..._dataSourceGoals]);
                    setDataTargetGroups([...selectedRowsData]);
                }}
            >
                <Column dataField={"GroupGoalID"} caption={Config.lang("Ma_nhom_muc_tieu")} width={150} />
                <Column dataField={"GroupGoalName"} caption={Config.lang("Ten_nhom_muc_tieu")} minWidth={200} />
            </ChooseModal>
            <PopupAttachment
                open={!!popupAttachments?.open}
                data={popupAttachments?.data || []}
                label={Config.lang("Dinh_kem")}
                onChange={onChangePopupAttachments}
                onClose={() => closeAttachments()}
                onAdd={onAddAttachments}
            />
            <ModalChangeTargetGroup
                open={dataChangeGroup.open}
                dataSource={dataTargetGroups}
                data={dataChangeGroup.data}
                onSave={e => changeTargetGroup(e)}
                onClose={() =>
                    setDataChangeGroup({
                        open: false,
                        data: null,
                    })
                }
            />
            <LoadPanel
                position={{ my: "center" }}
                visible={masterLoading || loading.save}
                showIndicator={true}
                shading={true}
                showPane={true}
                shadingColor={"rgba(0, 0, 0, 0.32)"}
            />
            <ActionToolbar title={Config.lang("Them_muc_tieu_hang_loat")}>
                <div className={"display_row align-between"} style={{ width: "100%" }}>
                    <Button
                        label={Config.lang("Luu")}
                        viewType="filled"
                        color="info"
                        startIcon="save"
                        disabled={disabled || !isDataUpdated || permissions.main < 2}
                        onClick={onSave}
                    />
                    <Button
                        startIcon={"Sheet"}
                        label={Config.lang("Xuat_excel")}
                        viewType={"outlined"}
                        color={"primary"}
                        disabled={disabled || !isLoadForm || _.isEmpty(refDataOld.current)}
                        onClick={exportExcel}
                    />
                </div>
            </ActionToolbar>
            {/*<div className={'hidden'}>{renderFilters()}</div>*/}
            <FormGroup className={"mgt4x"}>
                <Row>
                    <Col xs={12} sm={6} md={4} lg={4}>
                        <Radio
                            value={0}
                            name={"GoalMode"}
                            disabled={disabled || permissions?.W39F3003 <= 2}
                            checked={formData?.GoalMode === 0}
                            label={Config.lang("Muc_tieu_ca_nhan")}
                            onChange={e => handleChange("GoalMode", e)}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={8} lg={8}>
                        <Radio
                            value={1}
                            name={"GoalMode"}
                            disabled={disabled || permissions?.W39F3003 <= 2}
                            checked={formData?.GoalMode === 1}
                            label={Config.lang("Muc_tieu_co_cau_to_chuc")}
                            onChange={e => handleChange("GoalMode", e)}
                        />
                    </Col>
                    {formData?.GoalMode === 1 && (
                        <Col xs={12} sm={6} md={3} lg={3}>
                            <Dropdown
                                clearAble
                                required={true}
                                dataSource={dataOrgChart}
                                closeAfterSelect
                                disabled={disabled}
                                valueExpr={"OrgChartID"}
                                displayExpr={"OrgName"}
                                label={Config.lang("Co_cau_to_chuc")}
                                keyExpr={"OrgName"}
                                onChange={(e, data) => handleChange("OrgChartID", data)}
                                value={formData?.OrgChartID || ""}
                                loading={loading?.getOrgCharts}
                                style={{ margin: 0 }}
                                placeholder={Config.lang("Co_cau_to_chuc")}
                            >
                                <TreeView
                                    allowSearch
                                    dataSource={dataOrgChart}
                                    // disabledRelevantValue
                                    displayExpr={"OrgName"}
                                    id={"OrgChartID"}
                                    multipleValueMode="single"
                                    parentID={"OrgChartParentID"}
                                    value={formData?.OrgChartID || ""}
                                    valueExpr={"OrgChartID"}
                                    onChange={e => handleChange("OrgChartID", e)}
                                />
                            </Dropdown>
                        </Col>
                    )}
                    <Col xs={12} sm={6} md={3} lg={3}>
                        <Dropdown
                            clearAble
                            required={true}
                            disabled={disabled}
                            valueExpr={"EmployeeID"}
                            label={Config.lang("Nguoi_thuc_hien")}
                            placeholder={Config.lang("Chon")}
                            displayExpr={"{EmployeeID} - {EmployeeName}"}
                            dataSource={dataCboEmployees?.rows || []}
                            total={dataCboEmployees?.total || 0}
                            skip={filterCboEmployees.current.skip}
                            limit={filterCboEmployees.current.limit}
                            value={formData.Employee?.EmployeeID || ""}
                            loading={loading.getCboEmployees}
                            valueObjectDefault={formData.Employee}
                            onInput={e => {
                                filterCboEmployees.current.strSearch = e.target.value;
                                filterCboEmployees.current.skip = 0;
                                loadCboEmployees(true);
                            }}
                            onLoadMore={e => {
                                filterCboEmployees.current.skip = e.skip;
                                filterCboEmployees.current.limit = e.limit;
                                loadCboEmployees();
                            }}
                            onChange={e => handleChange("Employee", e)}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={3} lg={3}>
                        <Dropdown
                            clearAble
                            required={true}
                            disabled={disabled}
                            valueExpr={"GoalPlanID"}
                            label={Config.lang("Bang_muc_tieu")}
                            placeholder={Config.lang("Chon")}
                            displayExpr={"GoalPlanName"}
                            dataSource={dataSourceTargetTable}
                            value={formData.GoalPlanID}
                            loading={loading.getTableTarget}
                            onChange={e => handleChange("GoalPlanID", e)}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={3} lg={3}>
                        <Checkbox
                            disabled={disabled}
                            checked={selectFromCat}
                            label={Config.lang("Chon_muc_tieu_tu_danh_muc")}
                            value={0}
                            onChange={e => {
                                setSelectFromCat(e.value);
                            }}
                        />
                        <Checkbox
                            checked={formData?.StatusID === 2}
                            label={Config.lang("Hien_thi_muc_tieu_da_huy")}
                            value={2}
                            onChange={e => handleChange("StatusID", e)}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <div className={"display_row align-center flex-wrap"}>
                    <Typography type={"h3"} uppercase>
                        {Config.lang("Danh_sach_muc_tieu_ket_qua_then_chot")}
                    </Typography>
                    <Button
                        label={Config.lang("Chon_nhom_muc_tieu")}
                        viewType="filled"
                        color="primary"
                        startIcon="AddFilled"
                        className={"mgl2x"}
                        disabled={disabled || permissions.main < 2}
                        onClick={() => onOpenTargetGroup(true)}
                    />
                    <PopoverShowHideCol
                        data={dataHideColumns}
                        tabInfo={dataTabsHideColumns()}
                        FormID={FormID}
                        buttonProps={{ className: "mgl2x", disabled: disabled }}
                        onSubmit={data => {
                            setDataHideColumns(data);
                        }}
                    />
                </div>
            </FormGroup>
            <div ref={refContainer} style={{ minHeight: window.innerHeight - 269 }}>
                <AccordionGroup collapseOther={false}>{renderContents}</AccordionGroup>
            </div>
        </div>
    );
};

const ModalChangeTargetGroup = React.memo(props => {
    const { open, data, dataSource, onSave, onClose } = props;
    const [groupGoalID, setGroupGoalID] = useState(data?.GroupGoalID || "");

    const _onSave = () => {
        let event = {
            cancel: false,
            oldValue: data?.GroupGoalID,
            newValue: groupGoalID,
            data: data,
        };
        onSave && onSave(event);
        if (!event.cancel && onClose) onClose();
    };

    const _dataSource = useMemo(() => dataSource.filter(d => d.GroupGoalID !== data?.GroupGoalID), [dataSource, data]);
    return (
        <Modal zIndex={1020} width={480} open={open} onClose={() => onClose && onClose("close")}>
            <ModalHeader>{Config.lang("Cap_nhat_nhom_muc_tieu")}</ModalHeader>
            <ModalBody>
                <Dropdown
                    clearAble
                    searchDelayTime={700}
                    valueExpr={"GroupGoalID"}
                    label={Config.lang("Nhóm mục tiêu")}
                    placeholder={Config.lang("Chon")}
                    displayExpr={"GroupGoalName"}
                    dataSource={_dataSource}
                    className={"no-margin"}
                    value={groupGoalID}
                    onChange={e => setGroupGoalID(e?.value || "")}
                />
            </ModalBody>
            <ModalFooter className={"display_row"} boxShadow={"none"}>
                <Button label={Config.lang("Luu")} viewType="filled" color="info" startIcon="save" onClick={_onSave} />
            </ModalFooter>
        </Modal>
    );
});

export default W39F3060;
