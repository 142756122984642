/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 7/5/2021
 * @Example
 */

import { ArrowUp } from 'diginet-core-ui/icons';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Collapse, Typography } from 'diginet-core-ui/components';
import Config from '../../../../../config';
import W39F3030Goal from './W39F3030Goal';

const W39F3030GroupGoal = (props) => {
    const { data, classes, colorStatusArr } = props;

    const [expand, setExpand] = useState(true);

    const toogleExpand = () => {
        setExpand((expand) => !expand);
    };

    const listGoalRef = useRef(null);

    let position = {
        left: 0,
        x: 0,
    };

    const totalResult = data?.GroupGoal.reduce((total, current) => total + (current.Result * current.Proportion) / 100, 0).toFixed(2);
    const totalProportion = data?.GroupGoal.reduce((total, current) => total + current.Proportion, 0);

    const onMouseDown = (e) => {
        position = {
            left: listGoalRef.current.scrollLeft,
            x: e.clientX,
        };

        if (listGoalRef.current.scrollWidth > listGoalRef.current.clientWidth) {
            listGoalRef.current.addEventListener('mousemove', onMouseMove);
            listGoalRef.current.addEventListener('mouseup', onMouseUp);
        }
    };

    const onMouseMove = (e) => {
        const dx = e.clientX - position.x;

        if (listGoalRef.current) {
            listGoalRef.current.style.cursor = 'grabbing';
            listGoalRef.current.style.userSelect = 'none';
            listGoalRef.current.scrollLeft = position.left - dx;
        }
    };

    const onMouseUp = () => {
        listGoalRef.current.style.cursor = null;
        listGoalRef.current.style.removeProperty('user-select');
        listGoalRef.current.removeEventListener('mousemove', onMouseMove);
        listGoalRef.current.removeEventListener('mouseup', onMouseUp);
    };

    return (
        <div style={{ marginTop: 10 }}>
            <div
                className={`${classes.flex} ${classes.flexWrap} ${classes.alignCenter} ${classes.divGroupGoalName}`}
                style={{ borderColor: data?.Color ?? '' }}
            >
                <span
                    onClick={toogleExpand}
                    className={`${classes.iconExpandTransition} ${classes.pointerCursor}`}
                    style={{ color: data?.Color ?? '', transform: expand ? '' : 'rotate(180deg)' }}
                >
                    <ArrowUp width={24} height={24} viewBox color={'currentColor'} />
                </span>
                <Typography type={'h3'} color={data.Color}>
                    {`${data?.GroupGoalName ?? ''} (${data?.GroupGoal?.length ?? 0})`}
                </Typography>
            </div>
            <Collapse open={expand}>
                <div className={`${classes.autoOverflow} ${classes.gap5}`} onMouseDown={onMouseDown} ref={listGoalRef}>
                    {data?.GroupGoal?.length > 0 && (
                        <div className={`${classes.gridGoal} ${classes.gap10}`}>
                            <Typography type={'p2'} fullWidth={false}>
                                {Config.lang('Ten_muc_tieu')}
                            </Typography>
                            <div className={`${classes.flexColumn} ${classes.alignCenter}`}>
                                <Typography type={'p2'} fullWidth={false}>
                                    {Config.lang('Ket_qua')}
                                </Typography>
                                <Typography type={'p2'} fullWidth={false}>
                                    ({totalResult})
                                </Typography>
                            </div>
                            <div>
                                <Typography type={'p2'}>{Config.lang('Trong_so')}</Typography>
                                <Typography type={'p2'}>({totalProportion})</Typography>
                            </div>
                            <Typography type={'p2'}>{Config.lang('Han_hoan_thanh')}</Typography>
                            <Typography type={'p2'}>{Config.lang('Thuc_hien1')}</Typography>
                        </div>
                    )}
                    {data.GroupGoal.map((i, idx) => (
                        <W39F3030Goal key={i?.GoalID ?? idx} data={i} classes={classes} colorStatusArr={colorStatusArr} index={idx}/>
                    ))}
                </div>
            </Collapse>
        </div>
    );
};

W39F3030GroupGoal.propTypes = {
    data: PropTypes.object,
    classes: PropTypes.object,
    colorStatusArr: PropTypes.array,
};

W39F3030GroupGoal.defaultProps = {
    data: {},
    classes: {},
    colorStatusArr: [],
};

export default W39F3030GroupGoal;
