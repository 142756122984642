/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 6/5/2021
 * @Example
 */

import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => ({
    flex: {
        display: 'flex',
    },
    gap50: {
        gap: 50,
    },
    alignCenter: {
        alignItems: 'center',
    },
    wSnoWrap: {
        whiteSpace: 'nowrap',
    },
    divDetailCont: {
        marginTop: 8,
        borderTop: '1px solid',
        borderColor: theme.palette.text.subtext,
        position: 'relative',
    },
    typoDetail: {
        position: 'absolute',
        top: -10,
        paddingRight: 5,
        backgroundColor: 'white',
    },
    subColor: {
        color: theme.palette.text.subtext + ' !important',
    },
    justifyCenter: {
        justifyContent: 'center',
    },
    underlinedText: {
        textDecoration: 'underline',
    },
    pointerCursor: {
        cursor: 'pointer',
    },
    flexWrap: {
        flexWrap: 'wrap',
    },
    divGroupGoalName: {
        padding: '5px 0',
        borderBottom: '2px solid',
    },
    iconExpandTransition: {
        display: 'inline-flex',
        transition: 'all 0.2s ease-in-out',
    },
    autoOverflow: {
        overflow: 'auto',
    },
    hiddenOverflow: {
        overflow: 'hidden',
    },
    gap20: {
        gap: 20,
    },
    gap10: {
        gap: 10,
    },
    gap5: {
        gap: 5,
    },
    gridGoal: {
        display: 'grid',
        gridTemplateColumns: 'minmax(300px, 9fr) repeat(4, minmax(150px, 1fr))',
        width: 'fit-content',
        padding: '5px 0',
        alignItems: 'center',
    },
    gridTR: {
        display: 'grid',
        gridTemplateColumns: 'minmax(240px, 9fr) repeat(4, minmax(150px, 1fr))',
        width: 'fit-content',
        padding: '5px 0',
        alignItems: 'center',
    },
    divGoal: {
        borderTop: '1px solid #D4D5D9',
    },
    listTR: {
        width: 'calc(100% - 60px)',
        marginLeft: 60,
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    textOverflow: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
}));
