/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 6/5/2021
 * @Example
 */

import { Button, Checkbox, Typography } from 'diginet-core-ui/components';
import React, { useEffect, useRef, useState } from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Config from '../../../../config';
import * as approvalActions from '../../../../redux/approvals/approvals_actions';
import * as generalActions from '../../../../redux/general/general_actions';
import * as W39F3030Actions from '../../../../redux/W3X/W39F3030/W39F3030_actions';
import Api from '../../../../services/api';
import Approvals from '../../../approvals/approvals';
import { Combo } from '../../../common/form-material';
import DateBoxPicker from '../../../common/form-material/date-box';
import ActionToolbar from '../../../common/toolbar/action-toolbar';
import Filter from '../../../filter/filter';
import { useStyle } from './style';
import W39F3030GroupGoal from './utils/W39F3030GroupGoal';

const W39F3030 = (props) => {
    const classes = useStyle();

    const [loadingCboCreateUsers, setLoadingCboCreateUsers] = useState(false);
    const [loadingCboEmployees, setLoadingCboEmployees] = useState(false);
    const [disabledCboEmployee, setDisabledCboEmployee] = useState(false);
    const [dataForm, setDataForm] = useState([]);
    const [dataCboCreateUsers, setDataCboCreateUsers] = useState({
        rows: [],
        total: 0,
    });
    const [dataCboEmployees, setDataCboEmployees] = useState({
        rows: [],
        total: 0,
    });
    const [dataCboGoalTable, setDataCboGoalTable] = useState([]);
    const [DateFrom, setDateFrom] = useState(null);
    const [DateTo, setDateTo] = useState(null);
    const [DateFrom2, setDateFrom2] = useState(null);
    const [DateTo2, setDateTo2] = useState(null);
    const [filterEmployee, setFilterEmployee] = useState('');

    const filter = useRef({
        CreateUserID: '',
        ApprovalStatus: null,
        GoalPlanID: '',
    });
    const goalData = useRef({
        TransID: '',
    });
    const toolFilter = useRef(null);

    const [dataFilter, setDataFilter] = useState(() => {
        return {
            UserID: Config?.profile?.UserID ?? '',
            ApprovalStatus: filter.current?.ApprovalStatus ?? null,
            EmployeeID: filterEmployee?.EmployeeID ?? '',
            DepartmentID: '',
            ProjectID: '',
            TransType01ID: '',
            DutyID: '',
            IsRegisterType: '',
            Type: 0,
            VoucherID: '',
            CreateUserID: filter.current?.CreateUserID ?? '',
            GoalPlanID: filter.current?.GoalPlanID ?? '',
            DateFrom: DateFrom ?? null,
            DateTo: DateTo ?? null,
            DateFrom2: DateFrom2 ?? null,
            DateTo2: DateTo2 ?? null,
        };
    });

    const dispatch = useDispatch();
    const dataCboStatus = useSelector((state) => state.approvals.getCboStatus);

    const paramFilterCboCreateUsers = {
        skip: 0,
        limit: 10,
        search: '',
    };

    const paramFilterCboEmployees = {
        skip: 0,
        limit: 10,
        search: '',
    };

    const colorStatusArr = [
        {
            id: 0,
            label: Config.lang('Chua_hoan_thanh'),
            colorCode: Config.color ? Config.color['Success'] : '#00C875',
            colorStr: 'success',
        },
        {
            id: 1,
            label: Config.lang('Qua_han'),
            colorCode: Config.color ? Config.color['Danger'] : '#FF3D71',
            colorStr: 'danger',
        },
        {
            id: 2,
            label: Config.lang('Hoan_thanh'),
            colorCode: Config.color ? Config.color['Info'] : '#0095FF',
            colorStr: 'info',
        },
        {
            id: 3,
            label: Config.lang('Cham_tien_do'),
            colorCode: Config.color ? Config.color['Warning'] : '#FFAA00',
            colorStr: 'warning',
        },
    ];

    const loadCboCreateUsers = (isReset) => {
        const param = {
            Type: 'EmployeeID',
            FormID: 'W39F3030',
            Language: Config.language || '84',
            ...paramFilterCboCreateUsers,
        };
        setLoadingCboCreateUsers(true);
        dispatch(
            generalActions.getCboEmployees(param, (err, data) => {
                setLoadingCboCreateUsers(false);
                if (err) return Config.popup2.show('ERROR', err);
                if (data) {
                    const rows = data?.rows ?? data;
                    const total = data?.total ?? data?.length;
                    setDataCboCreateUsers({
                        rows: isReset ? rows : dataCboCreateUsers.rows.concat(rows),
                        total,
                    });
                }
            })
        );
    };

    const loadCboStatus = () => {
        const params = {
            FormID: 'W39F3030',
            Language: Config.language || '84',
        };
        dispatch(
            approvalActions.getCboStatus(params, (error) => {
                if (error) {
                    Config.popup2.show('ERROR', error);
                    return false;
                }
            })
        );
    };

    const loadCboEmployees = (isReset) => {
        const param = {
            Type: 'EmployeeID',
            FormID: 'W39F3030',
            Language: Config.language || '84',
            Disabled: +(disabledCboEmployee ?? false),
            ...paramFilterCboEmployees,
        };
        setLoadingCboEmployees(true);
        dispatch(
            generalActions.getCboEmployees(param, (err, data) => {
                setLoadingCboEmployees(false);
                if (err) return Config.popup2.show('ERROR', err);
                if (data) {
                    const rows = data?.rows ?? data;
                    const total = data?.total ?? data?.length;
                    setDataCboEmployees({
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total,
                    });
                }
            })
        );
    };

    useEffect(() => {
        setFilterEmployee(null);
        loadCboEmployees(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabledCboEmployee]);

    const loadCboGoalTable = async () => {
        const res = await Api.get('/W39F3001/load-table-target');
        const resData = res?.data ?? null;
        const resDataStatus = resData?.Status ?? 0;
        if (res && (!resData || resDataStatus === 1)) {
            const errMsg = resData?.Message ?? res?.message ?? Config.lang('Co_loi_xay_ra_trong_qua_trinh_xu_ly');
            return Config.popup2.show('ERROR', errMsg);
        }
        setDataCboGoalTable(resData);
    };

    const onLoadCboFilter = () => {
        loadCboCreateUsers();
        loadCboStatus();
        loadCboEmployees();
        loadCboGoalTable();
    };

    const filterChange = (key, value) => {
        switch (key) {
            case 'CreateUserID':
            case 'EmployeeID':
                if (value === null || value === undefined) {
                    loadCboEmployees(true);
                }
                setFilterEmployee(value ?? null);
                break;
            case 'ApprovalStatus':
                filter.current[key] = value?.value ? Number(value?.value) : null;
                break;
            case 'GoalPlanID':
                filter.current[key] = value ?? '';
                break;
            default:
                break;
        }
    };

    const renderFilter = () => {
        return (
            <Filter
                dropdownProps={{
                    ref: (ref) => (toolFilter.current = ref),
                }}
                placeholder={Config.lang('Noi_dung_can_tim_kiem')}
                showHeader={false}
                openOnFieldClick={true}
                onOpenLoaded={onLoadCboFilter}
                renderFilter={() => {
                    return (
                        <>
                            <div>
                                <Combo.LoadMore
                                    dataSource={dataCboCreateUsers}
                                    skip={paramFilterCboCreateUsers.skip}
                                    limit={paramFilterCboCreateUsers.limit}
                                    displayExpr={'EmployeeName'}
                                    keyExpr={'EmployeeID'}
                                    valueExpr={'EmployeeID'}
                                    value={filter.current?.CreateUserID ?? ''}
                                    loading={loadingCboCreateUsers}
                                    stylingMode={'outlined'}
                                    label={Config.lang('Nguoi_tao')}
                                    shrink={true}
                                    placeholder={Config.lang('Chon')}
                                    itemRender={(e) => {
                                        const { data } = e;
                                        if (!data) return null;
                                        return data.EmployeeID + ' - ' + data.EmployeeName;
                                    }}
                                    showClearButton={true}
                                    onValueChanged={(e) => filterChange('CreateUserID', e.value)}
                                    onInputChanged={(e) => {
                                        paramFilterCboCreateUsers.search = e.target.value;
                                        paramFilterCboCreateUsers.skip = 0;
                                        loadCboCreateUsers(true);
                                    }}
                                    onLoadMore={(e) => {
                                        paramFilterCboCreateUsers.skip = e.skip;
                                        paramFilterCboCreateUsers.limit = e.limit;
                                        loadCboCreateUsers();
                                    }}
                                />
                            </div>
                            <div>
                                <Combo
                                    label={Config.lang('Trang_thai')}
                                    shrink={true}
                                    placeholder={Config.lang('Chon')}
                                    dataSource={dataCboStatus}
                                    displayExpr={'AppStatusName'}
                                    keyExpr={'ApprovalStatus'}
                                    valueExpr={'ApprovalStatus'}
                                    onValueChanged={(e) => filterChange('ApprovalStatus', e)}
                                    showClearButton={true}
                                    value={String(filter.current?.ApprovalStatus ?? '')}
                                />
                            </div>
                            <div className={`${classes.flex} ${classes.gap50} ${classes.alignCenter}`}>
                                <Typography type={'h4'} className={`${classes.wSnoWrap} ${classes.subColor}`}>
                                    {Config.lang('Ngay_tao')}
                                </Typography>
                                <DateBoxPicker
                                    label={Config.lang('Tu')}
                                    shrink={true}
                                    dateBoxProps={{ max: DateTo ? DateTo : null }}
                                    onValueChanged={(e) => setDateFrom(e.value)}
                                    showClearButton={true}
                                    placeholder={'DD/MM/YYYY'}
                                    value={DateFrom ?? ''}
                                />
                                <DateBoxPicker
                                    label={Config.lang('Den')}
                                    shrink={true}
                                    dateBoxProps={{ min: DateFrom ? DateFrom : null }}
                                    onValueChanged={(e) => setDateTo(e.value)}
                                    showClearButton={true}
                                    placeholder={'DD/MM/YYYY'}
                                    value={DateTo ?? ''}
                                />
                            </div>
                            <div className={`${classes.divDetailCont}`}>
                                <Typography type={'h4'} className={`${classes.typoDetail} ${classes.subColor}`} fullWidth={false}>
                                    {Config.lang('Chi_tiet')}
                                </Typography>
                                <div style={{ marginTop: 16 }}>
                                    <Checkbox
                                        label={Config.lang('Da_nghi_viec')}
                                        checked={disabledCboEmployee}
                                        onChange={e => setDisabledCboEmployee(e?.value ?? false)}
                                    />
                                </div>
                                <div style={{ marginTop: 8 }}>
                                    <Combo.LoadMore
                                        dataSource={dataCboEmployees}
                                        skip={paramFilterCboEmployees.skip}
                                        limit={paramFilterCboEmployees.limit}
                                        displayExpr={'EmployeeName'}
                                        keyExpr={'EmployeeID'}
                                        valueExpr={'EmployeeID'}
                                        value={filterEmployee ?? null}
                                        loading={loadingCboEmployees}
                                        stylingMode={'outlined'}
                                        label={Config.lang('Nhan_vien')}
                                        shrink={true}
                                        placeholder={Config.lang('Chon')}
                                        itemRender={(e) => {
                                            const { data } = e;
                                            if (!data) return null;
                                            return data.EmployeeID + ' - ' + data.EmployeeName;
                                        }}
                                        showClearButton={true}
                                        onValueChanged={(e) => filterChange('EmployeeID', e.value)}
                                        onInputChanged={(e) => {
                                            paramFilterCboEmployees.search = e.target.value;
                                            paramFilterCboEmployees.skip = 0;
                                            loadCboEmployees(true);
                                        }}
                                        onLoadMore={(e) => {
                                            paramFilterCboEmployees.skip = e.skip;
                                            paramFilterCboEmployees.limit = e.limit;
                                            loadCboEmployees();
                                        }}
                                    />
                                </div>
                                <div>
                                    <Combo
                                        label={Config.lang('Bang_muc_tieu')}
                                        shrink={true}
                                        dataSource={dataCboGoalTable}
                                        displayExpr={'GoalPlanName'}
                                        valueExpr={'GoalPlanID'}
                                        onValueChanged={(e) => filterChange('GoalPlanID', e.value)}
                                        showClearButton={true}
                                        value={filter.current.GoalPlanID}
                                        placeholder={Config.lang('Chon')}
                                    />
                                </div>
                                <div className={`${classes.flex} ${classes.gap50} ${classes.alignCenter}`}>
                                    <Typography type={'h4'} className={`${classes.wSnoWrap} ${classes.subColor}`}>
                                        {Config.lang('Giai_doan')}
                                    </Typography>
                                    <DateBoxPicker
                                        label={Config.lang('Tu')}
                                        shrink={true}
                                        dateBoxProps={{ max: DateTo2 ? DateTo2 : null }}
                                        onValueChanged={(e) => setDateFrom2(e.value)}
                                        showClearButton={true}
                                        placeholder={'DD/MM/YYYY'}
                                        value={DateFrom2 ?? ''}
                                    />
                                    <DateBoxPicker
                                        label={Config.lang('Den')}
                                        shrink={true}
                                        dateBoxProps={{ min: DateFrom2 ? DateFrom2 : null }}
                                        onValueChanged={(e) => setDateTo2(e.value)}
                                        showClearButton={true}
                                        placeholder={'DD/MM/YYYY'}
                                        value={DateTo2 ?? ''}
                                    />
                                </div>
                            </div>
                            <div className={`${classes.flex} ${classes.justifyCenter}`} style={{ marginTop: 8 }}>
                                <Button
                                    text={Config.lang('Tim_kiem')}
                                    viewType="outlined"
                                    color={'primary'}
                                    onClick={onChangeDataFilter}
                                    startIcon={'Search'}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    const onChangeDataFilter = () => {
        toolFilter.current.instance.close();
        setDataFilter({
            ...dataFilter,
            ApprovalStatus: filter.current?.ApprovalStatus ?? null,
            EmployeeID: filterEmployee?.EmployeeID ?? '',
            CreateUserID: filter.current?.CreateUserID ?? '',
            GoalPlanID: filter.current?.GoalPlanID ?? '',
            DateFrom: DateFrom ?? null,
            DateTo: DateTo ?? null,
            DateFrom2: DateFrom2 ?? null,
            DateTo2: DateTo2 ?? null,
        });
    };

    const renderFormInfo = (data) => {
        return (
            <div>
                {data?.length > 0 &&
                    data.map((i, idx) => {
                        return <W39F3030GroupGoal key={i?.GroupGoalID ?? idx} data={i} classes={classes} colorStatusArr={colorStatusArr} />;
                    })}
                <Typography
                    type={'p2'}
                    className={`${classes.underlinedText} ${classes.pointerCursor}`}
                    color={'info'}
                    onClick={() => {
                        const params = objectToParams(goalData.current ?? {}) ?? '';
                        window.open(Config.getRootPath() + `W39F3010?${params}`);
                    }}
                    style={{ marginTop: 10 }}
                >
                    {Config.lang('Xem_chi_tiet_cac_dot_review')}
                </Typography>
            </div>
        );
    };

    const loadFormInfo = (transID, type) => {
        const params = {
            DivisionID: Config.getDivisionID() ?? '',
            FormID: 'W39F3030',
            UserID: Config?.profile?.UserID ?? '',
            Language: Config?.language ?? '84',
            VoucherID: transID ?? '',
            Type: 0,
            Mode: 0,
            TransType: type ?? '',
        };

        dispatch(
            W39F3030Actions.getDetailData(params, (err, dt) => {
                if (err) {
                    const msg = err?.message ?? Config.lang('Loi_chua_xac_dinh');
                    return Config.popup2.show('ERROR', msg);
                }
                if (dt?.data) {
                    setDataForm(dt?.data);
                }
            })
        );
    };

    const onSelectionChanged = (e) => {
        if (!e) return false;
        goalData.current.TransID = e?.D39T2046_TransID ?? '';
        goalData.current.IsEdit = 0;
        loadFormInfo(e.VoucherID, e.Type);
    };

    const objectToParams = (obj = {}) => {
        const arr = [];
        Object.keys(obj).forEach((key) => {
            arr.push(`${key}=${obj?.[key] ?? ''}`);
        });
        return arr.join('&');
    };

    return (
        <>
            <FormGroup>
                <ActionToolbar title={Config.lang('Duyet_ket_qua_muc_tieu_ca_nhan')} />
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Approvals
                            parentProps={props}
                            FormID={'W39F3030'}
                            dataFilter={dataFilter}
                            dataForm={dataForm}
                            singleClick
                            filterRender={renderFilter}
                            formInfoRender={renderFormInfo}
                            onSelectionChanged={onSelectionChanged}
                        />
                    </Col>
                </Row>
            </FormGroup>
        </>
    );
};

W39F3030.propTypes = {};

export default W39F3030;
